import React, { Dispatch, SetStateAction } from 'react';
import {
    Button,
    Checkbox,
    Flex, Image, Tooltip,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { firstColor, newChainAttrs, nftMintContractAddresses, secondColor, sftMintContractAddresses, thirdColor } from '../../utils/config';
import { CheckType, NftType, TokenSaleStatus, TokenType } from '../../utils/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, } from 'react';
import { useChainContext } from '../../utils/Context';
import Favorite from '../Favorite';
import TokenImage from '../TokenImage';

interface IProps {
    nftInfo: NftType;
    shouldShowCheckbox?: boolean;
    amount?: number;
    isMyToken?: boolean;
}

const SftCard = (props: IProps) => {
    const { nftInfo, shouldShowCheckbox, amount, isMyToken } = props
    const {
        _id,
        chainId,
        contractAddress,
        tokenId,
        tokenType,
        totalSupply,
        name,
        owners,
        artist,
        tokenSaleStatus,
        imageUrl,
        price,
        lastSalePrice,
        favoriteRate,
    } = nftInfo;
    const navigate = useNavigate();
    const {
        user,
        selectedTokenObjectIds,
        setSelectedTokenObjectIds,
    } = useChainContext();
    const [sftAmount, setSftAmount] = useState<number>(amount ?? 0);

    useEffect(() => {
        if (totalSupply && owners) {
            let userSftInfo = owners.find(item => item.address == user?.publicKey);
            setSftAmount(userSftInfo?.balance ?? 0);
        }
        if (!isMyToken) {
            setSftAmount(totalSupply ?? 0);
        }
        if (!totalSupply) {
            setSftAmount(amount ?? 0);
        }
    }, [totalSupply, owners, amount, user])

    const handleSelectedTokenObjectIds = async (tokenObjectId: string) => {
        let updatedSelectedTokenObjectIds: string[] = [];
        if (selectedTokenObjectIds.includes(tokenObjectId)) {
            updatedSelectedTokenObjectIds = selectedTokenObjectIds.filter(item => item != tokenObjectId);
        } else {
            updatedSelectedTokenObjectIds = [...selectedTokenObjectIds, tokenObjectId];
        }
        setSelectedTokenObjectIds(updatedSelectedTokenObjectIds);
    }


    return (
        <Flex
            className='sft-card'
            p={['10px']}
            position={'relative'}
            direction={['column']}
            background={thirdColor}
            width={['320px']}
            zIndex={0}
            border={owners && owners?.find(item => item.address == user?.publicKey) && owners?.find(item => item?.address == user?.publicKey)?.balance ? ['1px solid white'] : 'unset'}
        >
            {
                shouldShowCheckbox && (
                    <Flex
                        position={'absolute'}
                        top={'10px'}
                        right={['10px']}
                    >
                        <Checkbox
                            isChecked={selectedTokenObjectIds.includes(_id)}
                            onChange={() => handleSelectedTokenObjectIds(_id)}
                        />
                    </Flex>
                )
            }

            <TokenImage
                widthString={['300px']}
                imageUrl={imageUrl}
                isCsdogeToken={artist != null}
                shouldShowCheckbox={shouldShowCheckbox}
                onClick={() => shouldShowCheckbox && handleSelectedTokenObjectIds(_id)}
            />
            <Flex
                className='token-abstract'
                direction={'column'}
                px={['5px']}
                pt={['5px']}
            >
                <Flex
                    justifyContent={'center'}
                    fontFamily={'Poppins'}
                    className={'title'}
                    color={'white'}
                >
                    Csdoge Marketplace
                </Flex>
                <Flex
                    pt={['20px']}
                    direction={['column']}
                >
                    <Flex
                        direction={['column']}
                        color={'white'}
                    >
                        <Flex>
                            <Flex
                                fontFamily={'Poppins'}
                                gap={['5px']}
                                width={['50%']}
                            >
                                <Flex
                                >
                                    Chain:
                                </Flex>
                                <Image
                                    src={(newChainAttrs as any)[chainId].logo}
                                />
                            </Flex>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                TokenId: #{tokenId}
                            </Flex>
                        </Flex>

                        <Flex>
                            <Flex
                                fontFamily={'Poppins'}
                                width={'50%'}
                            >
                                Type: {tokenType == TokenType.NFT ? 'NFT' : 'SFT'}
                            </Flex>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                Amount: {sftAmount}
                            </Flex>
                        </Flex>
                        <Tooltip hasArrow label={name} placement='top' color={'white'} bg={firstColor} p={['5px 10px']} fontSize={['14px']}>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                Name: {name.length > 15 ? name.slice(0, 8) + '...' + name.slice(name.length - 6) : name}
                            </Flex>
                        </Tooltip>
                        <Flex
                            fontFamily={'Poppins'}
                            height={['24px']}
                        >
                            {
                                price != undefined && (tokenSaleStatus == TokenSaleStatus.NotInSale ? '' : `Price : ${price} ${newChainAttrs[chainId].nativeToken}`)
                            }
                        </Flex>
                        <Flex
                            fontFamily={'Poppins'}
                            height={['24px']}
                        >
                            {
                                lastSalePrice != undefined && (lastSalePrice == 0 ? '' : `Last sale : ${lastSalePrice} ${newChainAttrs[chainId].nativeToken}`)
                            }
                        </Flex>
                    </Flex>
                    <Flex
                        justifyContent={['center']}
                    >
                        <Button
                            zIndex={100}
                            bg={tokenSaleStatus != TokenSaleStatus.NotInSale ? firstColor : secondColor}
                            color={tokenSaleStatus != TokenSaleStatus.NotInSale ? 'white' : firstColor}
                            fontWeight={'900'}
                            _hover={{
                                background: secondColor,
                            }}
                            _selected={{
                                background: secondColor,
                            }}
                            px={['30px']}
                            py={['5px']}
                            opacity={0.3}
                            onClick={() => {
                                toast.warn("SFTs are not supported yet!");
                                // const ownerInfo = owners?.find(item => item?.address?.toLowerCase() == user?.publicKey?.toLowerCase())

                                // navigate(
                                //     ownerInfo && ownerInfo.balance > 0
                                //         ?
                                //         `/sft/${contractAddress}/${chainId}/${tokenId}/sell`
                                //         :
                                //         `/sft/${contractAddress}/${chainId}/${tokenId}`
                                // )
                            }}
                        >
                            View
                        </Button>
                    </Flex>
                </Flex>

                <Flex
                    justifyContent={['center']}
                    mt={['5px']}
                    height={['30px']}
                    position={'relative'}
                >
                    <Favorite
                        favoriteRate={favoriteRate ?? 0}
                        tokenObjectId={''}
                        setFavoriteRate={() => { }}
                        isRateChangable={false}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default SftCard;
