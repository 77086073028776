import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { firstColor } from './config'

const dark = '#232323'
const light = firstColor

const theme = extendTheme({
  styles: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    global: (props: any) => ({
      body: {
        bg: mode(light, dark)(props),
        color: mode('white', 'gray.800')(props),
      },
    }),
  },
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          width: ['auto', 'auto', '95%', '100%'],
          bg: firstColor,
          color: 'white',
        },
      }),
    },
  },
})

export default theme
