import axios from "axios";
import { readContract } from "@wagmi/core";
import { signContractAbi } from "./assets";
import { signContractAddresses } from "./config";
import { UserType } from "./types";
import { toast } from "react-toastify";

export const getSignature = async (
    user: UserType,
    chainId: number | undefined,
    contractAddress: string,
    tokenId: number,
    amount: number,
) => {
    if (user && chainId) {
        try {
            const hash = await readContract({
                address: signContractAddresses[chainId],
                abi: signContractAbi,
                functionName: 'getMessageHash',
                args: [
                    user.publicKey,
                    contractAddress,
                    tokenId,
                    amount,
                ],
            });

            console.log("hash: ", hash);

            const result = (await axios.post("/user/createSignature", {
                hash: hash,
                chainId: chainId,
            })).data;

            if (result.isSuccess) {
                console.log("signature: ", result.signature);
                return {
                    isSuccess: true,
                    signature: result.signature
                }
            } else {
                return {
                    isSuccess: false,
                    signature: ''
                }
            }
        } catch (e: any) {
            console.log('error in creating signature: ', e);
            toast.error('Unfortunately, your operation has failed: ', e);

            return {
                isSuccess: false,
                signature: ''
            }
        }
    }
}