import {
  Box,
  Flex,
  Grid,
  Image,
  Stack,
} from '@chakra-ui/react'
import NavLink from '../Header/Navlink'
import { firstColor, linkArray } from '../../utils/config'
import { discordLogo, instagramLogo, mediumLogo, telegramLogo, twitterLogo } from '../../utils/assets'

const Footer = () => {

  return (
    <>
      <Box
        zIndex='2'
        mt={['30px']}
      >
        <Box
          display={['block', 'block', 'block', 'block']}
          bg={firstColor}
          borderStyle={'solid'}
          width={'100%'}
          bottom={'0'}
          px={'20px'}
        >
          <Flex
            height={['unset', 'unset', 'unset', '70px']}
            justifyContent={['center', 'center', 'center', 'space-between']}
            alignItems={'center'}
            direction={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
          >
            <Flex
              display={['none', 'none', 'flex', 'flex']}
              gap={['20px', '20px', '20px', '20px']}
              my={['unset', 'unset', '20px', '20px', '0']}
              px='6'
              alignItems='center'
            >
              {
                linkArray.map((item, index) => (
                  <NavLink
                    key={index}
                    name={item.name}
                    footer
                    href={item.href}
                  />
                ))
              }
            </Flex>
            <Grid
              display={['grid', 'grid', 'none', 'none']}
              gridTemplateColumns={'repeat(2, 120px)'}
              my={['10px', '20px', '20px', '0']}
              mx={'auto'}
              columnGap={['15px', '100px']}
              rowGap={['10px']}
            >
              {
                linkArray.map((item, index) => (
                  <NavLink key={index} name={item.name} footer href={item.href} />
                ))
              }
            </Grid>
            <Stack
              direction={'row'}
              gap={['10px', '40px', '20px']}
            >
              <a href="https://medium.com/" target="_new">
                <Image
                  src={mediumLogo}
                  alt="Medium"
                  height={27}
                  width={27}
                />
              </a>
              <a href="http://t.me/" target="_new">
                <Image
                  src={telegramLogo}
                  alt="Telegram"
                  height={27}
                  width={27}
                />
              </a>
              <a href="http://discord.gg/" target="_new">
                <Image
                  src={discordLogo}
                  alt="Discord"
                  height={27}
                  width={27}
                />
              </a>
              <a href="https://twitter.com/" target="_new">
                <Image
                  src={twitterLogo}
                  alt="Twitter"
                  height={27}
                  width={27}
                />
              </a>

              <a href="https://www.instagram.com/" target="_new"
                style={{
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <Image
                  src={instagramLogo}
                  alt="Instagram"
                  height={17}
                  width={27}
                />
              </a>
            </Stack>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default Footer
