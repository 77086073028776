import React from 'react'
import { Outlet } from 'react-router-dom';
import { Flex } from '@chakra-ui/react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import { firstColor } from '../utils/config';
import ContextProvider from "../utils/ContextProvider";

const Layout = () => {

  return (
    <Flex
      minHeight='100vh'
      display='flex !important'
      flexDirection='column'
    >
      <ContextProvider>
        <>
          <Header />
          <Flex
            flexGrow='1 !important'
            background={firstColor}
          >
            <Outlet />
          </Flex>
          <Footer />
        </>
      </ContextProvider>
    </Flex>
  )
}

export default Layout
