import React from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Button, useDisclosure } from '@chakra-ui/react';
import axios, { HttpStatusCode } from 'axios';
import { useAccount, useNetwork } from 'wagmi';
import { disconnect, writeContract, switchNetwork } from '@wagmi/core'
import { ethers } from 'ethers';
import AnnounceModal from '../modal/AnnounceModal';
import { secondColor, nftSellContractAddresses, thirdColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { getSignature } from '../../utils/interact';
import { useState, } from 'react';
import { checkIconInGreenBg, nftSellContractAbi } from '../../utils/assets';
import { delay } from '../../utils/formatters';

type CompleteListingProps = {
    tokenId: number;
    nftContractAddress: string | undefined;
    isFixedSale: boolean;
    price: number | null;
    nftObjectId: string | null;
    durationInSeconds: number | undefined;
    shouldRender: boolean;
    setShouldRender: (shouldRender: boolean) => void;
}

const CompleteListing = ({
    tokenId,
    nftContractAddress,
    isFixedSale,
    price,
    nftObjectId,
    shouldRender,
    setShouldRender,
    durationInSeconds,
}: CompleteListingProps) => {
    const { chainId } = useParams();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { user } = useChainContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenListingSuccessModal,
        onOpen: onOpenListingSuccessModal,
        onClose: onCloseListingSuccessModal,
    } = useDisclosure();

    const listFixedSale = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'listFixedSale',
                args: [
                    nftContractAddress,
                    tokenId,
                    ethers.utils.parseEther(price!.toString()),
                    signature,
                ],
            });
            console.log("transaction hash: ", tx.hash);

            await axios.post('/fixedSale/registerFixedSale', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
                tokenType: 0,
                amount: 1,
                saleId: 0,
                salePrice: price,
                txh: tx.hash
            });

            await delay(15000);

            onOpenListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("list nft fixed sale log error: ", e);
                toast.error('Your operation failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const listNftAuction = async (signature: string) => {
        try {
            let startTimestamp = Math.round(Date.now() / 1000);
            let endTimestamp = startTimestamp + durationInSeconds!;

            const tx = await writeContract({
                address: (nftSellContractAddresses as any)[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'listNftAuction',
                args: [
                    nftContractAddress,
                    tokenId,
                    startTimestamp,
                    endTimestamp,
                    ethers.utils.parseEther(price!.toString()),
                    signature,
                ],
            });

            console.log("transaction hash: ", tx.hash);

            await axios.post('/auction/registerAuction', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
                auctionStartTimestamp: startTimestamp,
                auctionEndTimestamp: endTimestamp,
                startPrice: price,
                txh: tx.hash
            });

            await delay(20000);

            onOpenListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("list nft auction tx error: ", e);
                toast.error('Your operation failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const completeListing = async () => {
        if (!chain || !user) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (parseInt(chainId ?? '') != chain?.id) {
            await switchNetwork({ chainId: Number(chainId) });
        }
        if (!price || price < 0) {
            toast.error('Please type price at first');
            return;
        }
        if (!isFixedSale && !durationInSeconds) {
            toast.error('Please select duration at first');
            return;
        }

        setIsProcessing(true);
        const signatureResult = await getSignature(
            user!,
            Number(chainId),
            nftContractAddress!,
            // nftContractAddress as string,
            tokenId,
            1, // for compatibility with sign contract
        )
        if (!signatureResult?.isSuccess) {
            toast.error('Signature Error');
            setIsProcessing(false);
            return;
        }

        if (isFixedSale) {
            await listFixedSale(signatureResult.signature);
        } else {
            await listNftAuction(signatureResult.signature);
        }
    }

    return (
        <>
            <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'7px'}
                mt={'30px'}
                onClick={() => completeListing()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['240px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                fontWeight={'900'}
                _hover={{
                    background: secondColor,
                }}
                _selected={{
                    background: secondColor,
                }}
            >
                Complete listing
            </Button>
            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenListingSuccessModal}
                onCloseAnnounceModal={() => {
                    setShouldRender(!shouldRender);
                    onCloseListingSuccessModal();
                }}
                announceText={'Your transaction is success'}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />
        </>
    )
}

export default CompleteListing;
