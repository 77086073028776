import {
  Button,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useState, useEffect, } from 'react';
import axios, { HttpStatusCode } from 'axios';
import { toast } from 'react-toastify';
import { useNetwork } from 'wagmi';
import { disconnect, writeContract, readContract } from '@wagmi/core';
import AnnounceModal from '../../components/modal/AnnounceModal';
import { checkIconInGreenBg, nftMintContractAbi, sftMintContractAbi, } from '../../utils/assets';
import { categoryList, nftMintContractAddresses, sftMintContractAddresses, secondColor, thirdColor, } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { getSignature } from '../../utils/interact';
import { delay } from '../../utils/formatters';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { DestinationWalletInfoType, NftOwnerInfo, TokenType } from '../../utils/types';

let flag = false;

const Mint = () => {
  const { chain, } = useNetwork();
  const { user, sockets } = useChainContext();
  const [image, setImage] = useState<any>(undefined)
  const [previousImg, setPreviousImg] = useState<any>(undefined);
  const [thumbnail, setThumbnail] = useState();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [artistName, setArtistName] = useState<string>('');
  const [tokenCategory, setTokenCategory] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isHeightBiggerThanWidth, setIsHeightBiggerThanWidth] = useState<boolean>(false);
  const [tokenType, setTokenType] = useState<string>('0');
  const [destinationType, setDestionationType] = useState<string>('0');
  const [totalSupply, setTotalSupply] = useState<number | null>(1);
  const [otherDestinationWalletList, setOtherDestinationWalletList] = useState<DestinationWalletInfoType[]>([{
    address: '',
    amount: '1'
  }]);

  const {
    isOpen: isOpenMintSuccessModal,
    onOpen: onOpenMintSuccessModal,
    onClose: onCloseMintSuccessModal,
  } = useDisclosure();

  useEffect(() => {
    if (!(sockets && sockets.userSocket)) return;

    if (flag) return;
    flag = true;

    sockets.userSocket.on("mintedNewNFT", () => {
      console.log("mintedNewNFT");

      onOpenMintSuccessModal();
      setIsProcessing(false);
    });
  }, [sockets?.userSocket]);

  useEffect(() => {
    if (!thumbnail) {
      setPreviousImg(undefined);
      return;
    }

    const img: HTMLImageElement = document.createElement("img");
    const objectUrl = URL.createObjectURL(thumbnail);
    img.src = objectUrl;

    img.onload = function () {
      setIsHeightBiggerThanWidth(img.height > img.width);
    };

    setPreviousImg(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [thumbnail]);

  function handleFile(files: any) {
    setThumbnail(files[0]);
  }

  // manage to open an image
  const handleImageOpen = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const fileSize = e.target.files[0].size;
      const fileType = e.target.files[0].type;

      console.log('fileSize', fileSize);
      console.log('fileType', fileType);

      if (fileSize / 1024 / 1024 > 2) {
        toast.error('The file size should be smaller than 2MB.');
        return;
      }

      handleFile(e.target.files);

      const render = new FileReader();
      render.readAsDataURL(e.target.files[0]);

      render.onload = async () => {
        if (render.readyState === 2) {
          setImage(render?.result?.toString());
        }
      }
    }
  }

  // upload an image to ipfs
  const uploadToPinata = async () => {
    if (!image) {
      toast.error('Please choose file at first');
      return;
    }
    if (!name) {
      toast.error('Please type name at first');
      return;
    }
    if (!tokenCategory) {
      toast.error('Please choose category at first');
      return;
    }

    setIsProcessing(true);

    try {
      const result = (await axios.post(`/nft/uploadImage`, {
        image: image,
        name: name,
        artist: artistName ?? 'Anonymous',
        category: tokenCategory,
        description: description
      })).data

      let metadataUrl;
      if (result.isSuccess) {
        metadataUrl = result.metadataUrl;

        return {
          isSuccess: true,
          metadataUrl,
          imageUrl: result.imageUrl,
        }
      } else {
        return {
          isSuccess: false,
        }
      }
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
        disconnect();
      } else {
        console.log("error in upload image in pinata: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
      setIsProcessing(false);
    }
  }

  // mint nft
  const mintNFT = async () => {
    if (!chain || !user) {
      toast.error('Please connect your wallet at first');
      return;
    }

    const result = await uploadToPinata();

    if (!result?.isSuccess) {
      setIsProcessing(false);
      return;
    } else {
      try {
        const nftMintContractAddress = nftMintContractAddresses[chain.id];

        const tokenId = Number(await readContract({
          address: nftMintContractAddress,
          abi: nftMintContractAbi,
          functionName: 'getTotalSupply',
        })) + 1;

        const signatureResult = await getSignature(
          user,
          chain.id,
          nftMintContractAddress,
          tokenId,
          1, // for compatibility with sign contract
        );

        if (!signatureResult?.isSuccess) {
          toast.error('Signature Error');
          setIsProcessing(false);
          return;
        }

        const mintingFee = await readContract({
          address: nftMintContractAddress,
          abi: nftMintContractAbi,
          functionName: 'mintingFee',
        });

        console.log('mintingFee', mintingFee);

        const tx = await writeContract({
          address: nftMintContractAddress,
          abi: nftMintContractAbi,
          functionName: 'mint',
          value: mintingFee as any,
          args: [
            user.publicKey,
            result.metadataUrl,
            signatureResult.signature,
          ],
        });

        console.log('txh: ', tx.hash);

        await axios.post('/nft/register', {
          chainId: chain!.id,
          tokenId: tokenId,
          contractAddress: nftMintContractAddress,
          name: name,
          description: description,
          owner: user.publicKey,
          artist: artistName ?? 'Anonymous',
          category: tokenCategory,
          imageUrl: result.imageUrl,
          metadataUrl: result.metadataUrl,
        });

        await delay(15000);

        onOpenMintSuccessModal();
        setIsProcessing(false);
      } catch (e: any) {
        if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
          toast.error('Please sign in again');
          disconnect();
        } else {
          console.log("error in minting nft: ", e);
          toast.error('Unfortunately, your operation has failed: ', e);
        }

        setIsProcessing(false);
      }
    }
  }

  const updateIthWalletInfo = (index: number, ithWalletInfo: any) => {
    setOtherDestinationWalletList((current) => {
      current[index] = {
        ...current[index],
        ...ithWalletInfo,
      }
      return [...current]
    });
  }

  // mint sft
  const mintSFT = async () => {
    if (!chain || !user) {
      toast.error('Please connect your wallet at first');
      return;
    }
    if (tokenType == "1" && Number(totalSupply ?? 0) <= 0) {
      toast.error('Please type the exact total supply');
      return;
    }
    if (tokenType == "1" && destinationType == "1") {
      let sumAmount = 0;
      for (let i = 0; i < otherDestinationWalletList.length; i++) {
        let ithWalletInfo = otherDestinationWalletList[i];
        sumAmount += Number(ithWalletInfo.amount);
        if (ithWalletInfo.address.length != 42 || Number(ithWalletInfo.amount) <= 0) {
          toast.error(`Please type ${i + 1}th wallet data correctly`);
          return;
        }
      }
      if (sumAmount != totalSupply) {
        toast.error('Total sum should be equal to total supply');
        return;
      }
    }

    const result = await uploadToPinata();

    if (!result?.isSuccess) {
      setIsProcessing(false);
      return;
    } else {
      try {
        const tokenId = Number(await readContract({
          address: (sftMintContractAddresses as any)[chain!.id],
          abi: sftMintContractAbi,
          functionName: 'currentTokenId',
        })) + 1;

        const signatureResult = await getSignature(
          user,
          chain.id,
          (sftMintContractAddresses as any)[chain!.id],
          tokenId,
          destinationType == "0" ? Number(totalSupply) : 1,
        );

        if (!signatureResult?.isSuccess) {
          toast.error('Signature Error');
          setIsProcessing(false);
          return;
        }

        let owners: NftOwnerInfo[] = [];
        if (destinationType == "0") {
          owners = [
            {
              address: user?.publicKey?.toLowerCase(),
              balance: totalSupply!
            }
          ];
        } else {
          owners = otherDestinationWalletList.map((item) => {
            return {
              address: item.address.toLowerCase(),
              balance: Number(item.amount),
            }
          });
        }

        const sftMintContractAddress = sftMintContractAddresses[chain.id];

        await axios.post('/nft/register', {
          chainId: chain!.id,
          tokenId: tokenId,
          contractAddress: sftMintContractAddress,
          tokenType: TokenType.SFT,
          totalSupply,
          name: name,
          description: description,
          owners,
          artist: artistName ?? 'Anonymous',
          category: tokenCategory,
          imageUrl: result.imageUrl,
          metadataUrl: result.metadataUrl,
        });

        if (destinationType == "0") {
          const tx = await writeContract({
            address: (sftMintContractAddresses as any)[chain!.id],
            abi: sftMintContractAbi,
            functionName: 'mint',
            args: [
              user.publicKey,
              result.metadataUrl,
              totalSupply,
              signatureResult.signature,
            ],
          });

          console.log('txh: ', tx.hash);
        } else {
          const tx = await writeContract({
            address: (sftMintContractAddresses as any)[chain!.id],
            abi: sftMintContractAbi,
            functionName: 'mintToManyAddresses',
            args: [
              otherDestinationWalletList.map(item => item.address),
              otherDestinationWalletList.map(item => item.amount),
              result.metadataUrl,
              signatureResult.signature,
            ],
          });

          console.log('txh: ', tx.hash);
        }
      } catch (e: any) {
        if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
          toast.error('Please sign in again');
          disconnect();
        } else {
          console.log("error in minting sft: ", e);
          toast.error('Unfortunately, your operation has failed: ', e);
        }

        setIsProcessing(false);
      }
    }
  }

  return (
    <Flex
      className='mint-page-wrapper-component'
      width={'100%'}
      justifyContent={'center'}
    >
      <Flex
        className='mint-page-component'
        justifyContent={'center'}
        direction={'column'}
        py={['50px', '50px', '100px']}
      >
        <Flex
          direction={['column', 'column', 'row']}
          mt={['10px']}
          mb={['20px']}
          gap={['30px', '30px', '100px']}
        >
          <Flex
            direction={['column']}
            alignItems={['center']}
            gap={['50px']}
          >
            <Flex
              className="token-image-wrapper"
              border={'1px solid white'}
              position={['relative']}
              width={['300px', '350px', '350px', '400px']}
              height={['300px', '350px', '350px', '400px']}
              direction={['column']}
              bg={thirdColor}
              onClick={() => { document.getElementById('getFile')!.click() }}
              display={['flex']}
              justifyContent={['center']}
              alignItems={['center']}
            >
              <img
                src={previousImg}
                className={`token-image ${isHeightBiggerThanWidth ? 'h-full' : 'w-full'}`}
              />
              <Flex
                position={['absolute']}
                height={['250px']}
                alignItems={['center']}
                left={['50%']}
                top={['50%']}
                transform={['translate(-50%, -50%)']}
                className='okay'
              >
                <Input
                  onChange={handleImageOpen}
                  id='getFile'
                  type={'file'}
                  border={['none']}
                  width={'116px'}
                  display={'none'}
                />
              </Flex>
            </Flex>
            <Flex
              color={'white'}
              width={['240px']}
              height={['45px']}
              fontSize={['16px']}
              border={`1px solid ${secondColor}`}
              background={thirdColor}
              borderRadius={['20px']}
              justifyContent={'center'}
              alignItems={['center']}
              fontFamily={'Poppins'}
              onClick={() => { document.getElementById('getFile')!.click() }}
              cursor={'pointer'}
              _hover={{
                background: secondColor,
                color: 'black',
                fontStyle: 'bold',
              }}
              _selected={{
                background: secondColor,
                color: 'black',
                fontStyle: 'bold',
              }}
            >
              Upload Image
            </Flex>
          </Flex>

          <Flex
            className='token-infos'
            direction={'column'}
            width={['300px', '350px', '300px', '350px']}
          >
            <Flex
              className="name"
              direction={'column'}
              mt={['40px', '40px', 'unset']}
              gap={['10px']}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'22px'}
                lineHeight={'30px'}
                color={'white'}
                textTransform={'capitalize'}
              >
                Name
              </Flex>
              <Input
                value={name}
                variant="flushed"
                placeholder='Type the name'
                borderBottom={`2px solid ${secondColor}`}
                onChange={(e) => setName(e.target.value)}
                color={'white'}
                fontFamily={'Poppins'}
                _focusVisible={{
                  boxShadow: 'none',
                }}
              />
            </Flex>

            <Flex
              className="description"
              direction={'column'}
              mt={['40px']}
              gap={['10px']}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'22px'}
                lineHeight={'30px'}
                color={'white'}
                textTransform={'capitalize'}
              >
                Description
              </Flex>
              <Input
                value={description}
                variant="flushed"
                placeholder='Type the description'
                borderBottom={`2px solid ${secondColor}`}
                onChange={(e) => setDescription(e.target.value)}
                color={'white'}
                fontFamily={'Poppins'}
                _focusVisible={{
                  boxShadow: 'none',
                }}
              />
            </Flex>

            <Flex
              className="airtst"
              direction={'column'}
              mt={['40px']}
              gap={['10px']}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'22px'}
                lineHeight={'30px'}
                textTransform={'capitalize'}
                color={'white'}
              >
                Artist
              </Flex>
              <Input
                value={artistName}
                color={'white'}
                variant="flushed"
                placeholder='Type the artist name'
                borderBottom={`2px solid ${secondColor}`}
                onChange={(e) => setArtistName(e.target.value)}
                fontFamily={'Poppins'}
                _focusVisible={{
                  boxShadow: 'none',
                }}
              />
            </Flex>

            <Flex
              className='token-category'
              mt={'40px'}
              direction={'column'}
              gap={['10px']}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'22px'}
                lineHeight={'30px'}
                color={'white'}
                textTransform={'capitalize'}
                className='title'
              >
                Category
              </Flex>

              <Select
                placeholder='Select Category'
                border={'2px solid #739AF0 !important'}
                fontFamily={'Poppins'}
                fontWeight={700}
                fontSize={'18px'}
                lineHeight={'25px'}
                width={['300px', '350px', '300px', '350px']}
                height={'41px'}
                borderRadius={'5px'}
                onChange={(e) => {
                  setTokenCategory(e.target.value);
                }}
                value={tokenCategory}
                className='category-select'
              >
                {
                  categoryList.sort((a: string, b: string) => a == b ? 0 : a > b ? 1 : -1).map((item, index) => (
                    <option
                      value={item}
                      key={index}
                    >
                      {item}
                    </option>
                  ))
                }
                <option
                  value={'Other'}
                >
                  Other
                </option>
              </Select>
            </Flex>

            {/* <Flex
              className='token-type'
              mt={'40px'}
              direction={'column'}
              gap={['10px']}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'22px'}
                lineHeight={'30px'}
                color={'white'}
                textTransform={'capitalize'}
              >
                Type
              </Flex>

              <RadioGroup
                mt={'10px'}
                onChange={setTokenType}
                value={tokenType}
              >
                <Stack direction='row'>
                  <Radio
                    value='0'
                    fontFamily={'Poppins'}
                    width={['50%']}
                    fontWeight={'400'}
                    fontSize={'16px'}
                    lineHeight={'30px'}
                    color={'white'}
                  >
                    NFT
                  </Radio>
                  <Radio
                    value='1'
                    fontFamily={'Poppins'}
                    width={['50%']}
                    fontWeight={'400'}
                    fontSize={'16px'}
                    lineHeight={'30px'}
                    color={'white'}
                  >
                    SFT
                  </Radio>
                </Stack>
              </RadioGroup>
            </Flex> */}

            {
              tokenType == "1" && (
                <Flex
                  className="total-supply"
                  direction={'column'}
                  mt={'40px'}
                  gap={['10px']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'22px'}
                    lineHeight={'30px'}
                    color={'white'}
                    textTransform={'capitalize'}
                  >
                    Total Supply
                  </Flex>
                  <Input
                    value={totalSupply == null ? "" : totalSupply}
                    variant="flushed"
                    placeholder='Type the total supply'
                    borderBottom={`2px solid ${secondColor}`}
                    onChange={(e) => setTotalSupply(e.target.value ? Number(e.target.value) : null)}
                    color={'white'}
                    fontFamily={'Poppins'}
                    _focusVisible={{
                      boxShadow: 'none',
                    }}
                  />
                </Flex>
              )
            }

            {
              tokenType == "1" && (
                <Flex
                  className='destination-type'
                  mt={'40px'}
                  direction={'column'}
                  gap={['10px']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'22px'}
                    lineHeight={'30px'}
                    color={'white'}
                    textTransform={'capitalize'}
                  >
                    Destination Type
                  </Flex>

                  <RadioGroup
                    mt={'10px'}
                    onChange={setDestionationType}
                    value={destinationType}
                  >
                    <Stack direction='row'>
                      <Radio
                        value='0'
                        fontFamily={'Poppins'}
                        width={['50%']}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'30px'}
                        color={'white'}
                      >
                        My Wallet
                      </Radio>
                      <Radio
                        value='1'
                        fontFamily={'Poppins'}
                        width={['50%']}
                        fontWeight={'400'}
                        fontSize={'16px'}
                        lineHeight={'30px'}
                        color={'white'}
                      >
                        Other Wallet
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>
              )
            }

            {
              tokenType == "1" && destinationType == "1" && (
                <Flex
                  direction={'column'}
                  className='other-destination-wallet-list'
                  gap={'10px'}
                  mt={'30px'}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'22px'}
                    lineHeight={'30px'}
                    color={'white'}
                    textTransform={'capitalize'}
                  >
                    Other Wallets
                  </Flex>

                  {
                    otherDestinationWalletList.map((item: DestinationWalletInfoType, index: number) => (
                      <Flex
                        key={index}
                        justifyContent={'space-evenly'}
                        gap={['10px']}
                      >
                        <Input
                          value={item.address}
                          placeholder={`Wallet ${index + 1}`}
                          variant="flushed"
                          borderBottom={'2px solid #739AF0 !important'}
                          onChange={(e) => updateIthWalletInfo(index, { address: e.target.value })}
                          fontFamily={'Poppins'}
                          _focusVisible={{
                            boxShadow: 'none',
                          }}
                        />

                        <Input
                          value={item.amount}
                          width={['90px']}
                          textAlign={['right']}
                          placeholder={`Amount ${index + 1}`}
                          variant="flushed"
                          borderBottom={'2px solid #739AF0 !important'}
                          onChange={(e) => updateIthWalletInfo(index, { amount: e.target.value })}
                          fontFamily={'Poppins'}
                          _focusVisible={{
                            boxShadow: 'none',
                          }}
                        />

                        <Flex
                          direction={'column'}
                          justifyContent={'center'}
                          onClick={index == otherDestinationWalletList.length - 1 ? () => {
                            setOtherDestinationWalletList([...otherDestinationWalletList, {
                              address: '',
                              amount: '1',
                            }])
                          } : () => { }}
                          visibility={index == otherDestinationWalletList.length - 1 ? 'visible' : 'hidden'}
                          cursor={'pointer'}
                        >
                          <AiOutlinePlusCircle />
                        </Flex>
                      </Flex>
                    ))
                  }
                </Flex>
              )
            }
          </Flex>
        </Flex>

        <Flex
          gap={['20px', '20px', '30px',]}
          mt={['50px']}
          justifyContent={'center'}
        >
          {
            tokenType == '0' ? (
              <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'7px'}
                mt={'30px'}
                onClick={() => mintNFT()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['200px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                fontWeight={'900'}
                _hover={{
                  background: secondColor,
                }}
                _selected={{
                  background: secondColor,
                }}
              >
                Mint NFT
              </Button>
            ) : (
              <Flex
                gap={['10px', '20px', '30px']}
                direction={['column', 'row']}
              >
                <Button
                  border={`1px solid ${secondColor}`}
                  borderRadius={'45px'}
                  px={'28px'}
                  py={'7px'}
                  mt={'30px'}
                  onClick={() => mintSFT()}
                  fontFamily={'Poppins'}
                  fontSize={'16px'}
                  lineHeight={'19px'}
                  cursor={'pointer'}
                  width={['200px']}
                  height={['45px']}
                  background={thirdColor}
                  color={'white'}
                  fontWeight={'900'}
                  _hover={{
                    background: secondColor,
                  }}
                  _selected={{
                    background: secondColor,
                  }}
                >
                  {destinationType == "0" ? 'Mint SFT' : 'Mint SFT And Send'}
                </Button>
              </Flex>
            )
          }
        </Flex>

        <AnnounceModal
          isOpenAnnounceModal={isProcessing}
          onCloseAnnounceModal={() => setIsProcessing(false)}
          announceText={'Your transaction is currently processing'}
          announceGif={true}
          announceModalButtonText={'Close'}
        />
        <AnnounceModal
          isOpenAnnounceModal={isOpenMintSuccessModal}
          onCloseAnnounceModal={onCloseMintSuccessModal}
          announceText={`Mint has been successfully done`}
          announceLogo={checkIconInGreenBg}
          announceModalButtonText={'Close'}
        />
      </Flex>
    </Flex>
  )
}

export default Mint
