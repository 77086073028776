import {
    Flex, Image, Input, Table, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AuctionType, BidType, TokenSaleStatus, NftType, UserType, BidderInfoType } from '../../utils/types';
import { useParams, } from 'react-router-dom';
import { useNetwork } from 'wagmi';
import axios from 'axios';
import { explorers, newChainAttrs, nftMintContractAddresses, secondColor } from '../../utils/config';
import { getEllipsisTxt, getFormattedDateString, getFormattedTime, } from '../../utils/formatters';
import copy from 'copy-text-to-clipboard';
import { useChainContext } from '../../utils/Context';
import BuyFromFixedSale from '../../components/NFTDetails/BuyFromFixedSale';
import MakeBid from '../../components/NFTDetails/MakeBid';
import MakeOffer from '../../components/NFTDetails/MakeOffer';
import Favorite from '../../components/Favorite';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import CountDownRenderer from '../../components/ListForSale/CountDownRenderer';
import TokenImage from '../../components/TokenImage';

const NFTDetails = () => {
    const [nftInfo, setNftInfo] = useState<NftType>();
    const { contractAddress, chainId, tokenId } = useParams();
    const [favoriteRate, setFavoriteRate] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myBidPrice, setMyBidPrice] = useState<number | null>(null);
    const [biddersInfo, setBiddersInfo] = useState<BidderInfoType[]>([]);
    const { user } = useChainContext();

    useEffect(() => {
        getNFTForTokenId();
    }, [chainId, tokenId])

    const getNFTForTokenId = async () => {
        try {
            setIsLoading(true);
            const [nftForTokenIdRes, biddersRes] = await Promise.all([
                axios.get(`/nft/${chainId}/${tokenId}/${contractAddress}`),
                axios.get(`/bid/${chainId}/${tokenId}/${contractAddress}`)
            ]);

            const [nftForTokenId, bidders] = [nftForTokenIdRes.data, biddersRes.data];

            console.log("nftForTokenId: ", nftForTokenId);

            setNftInfo(nftForTokenId);
            setBiddersInfo(bidders);
        } catch (e: any) {
            console.log('error in getting nft: ', e);
            toast.error('Unfortunately, your operation has failed: ', e);
        }
        setIsLoading(false);
    }

    const getBidInfo = async (auctionObjectId: string | undefined) => {
        try {
            if (auctionObjectId) {
                const bid: BidType = ((await axios.get(`/bid/get/${auctionObjectId}/${user?._id}`)).data);
                if (bid) setMyBidPrice(bid.bidPrice);
            }
        } catch (e: any) {
            console.log('error in getting bid info: ', e);
            toast.error('Unfortunately, your operation has failed: ', e);
        }
    }

    useEffect(() => {
        console.log("user: ============= ", user);
        if (user && nftInfo) {
            getBidInfo(nftInfo?.currentAuction?._id);
            getFavoriteRate(nftInfo!._id, user);
        }
    }, [user, nftInfo])

    const getFavoriteRate = async (nftObjectId: string, user: UserType | null) => {
        if (user) {
            try {
                const favoriteRate = (await axios.get(`/favorite/user/${nftObjectId}/${user?._id}`)).data;
                console.log("favorite rate: ", favoriteRate ? favoriteRate : 0);
                setFavoriteRate(favoriteRate);
            } catch (e: any) {
                console.log('error in getting my favorite rate: ', e);
                toast.error('Unfortunately, your operation has failed: ', e);
            }
        }
    }

    return (
        <Flex
            className='nft-details-page-wrapper-component'
            width={'100%'}
            justifyContent={'center'}
        >
            {
                isLoading && (
                    <Flex
                        className="loading-container"
                        justifyContent={'center'}
                        mt={'100px'}
                        alignItems={'center'}
                    >
                        <section>
                            <Flex className="loader loader-1">
                                <Flex className="loader-outter" />
                                <Flex className="loader-inner" />
                            </Flex>
                        </section>
                    </Flex>
                )
            }
            {
                !isLoading && nftInfo && chainId != undefined && (
                    Object.keys(newChainAttrs).includes(chainId) ? (
                        <Flex
                            className='nft-details-page-wrapper-component'
                            width={'100%'}
                            justifyContent={'center'}
                        >
                            <Flex
                                className='nft-details-page-component'
                                justifyContent={'center'}
                                direction={'column'}
                                py={['50px', '50px', '100px']}
                            >
                                <Flex
                                    direction={['column', 'column', 'row']}
                                    mt={['10px']}
                                    mb={['20px']}
                                    gap={['30px', '30px', '100px']}
                                >
                                    <Flex
                                        direction={['column']}
                                        alignItems={['center']}
                                        gap={['20px', '20px', '20px', '10px']}
                                    >
                                        <Flex
                                            className="nft-image-wrapper"
                                            position={['relative']}
                                            width={['300px', '350px', '350px', '400px']}
                                            height={['300px', '350px', '350px', '400px']}
                                            direction={['column']}
                                            alignItems={['center']}
                                        >
                                            <Flex
                                                position={'absolute'}
                                                bottom={'-30px'}
                                                left={0}
                                                display={'flex'}
                                                direction={'row'}
                                                alignItems={'center'}
                                                gap={'5px'}
                                            >
                                                <Image
                                                    width={'25px'}
                                                    src={newChainAttrs[chainId].logo}
                                                />
                                            </Flex>
                                            <TokenImage
                                                widthString={['300px', '350px', '350px', '400px']}
                                                imageUrl={nftInfo.imageUrl}
                                                hasBorder
                                                isCsdogeToken={nftInfo.artist != null}
                                            />
                                        </Flex>
                                        {
                                            nftInfo && user && (
                                                <>
                                                    <p className='text-[20px]'>Your Rating</p>
                                                    <Favorite
                                                        tokenObjectId={nftInfo._id}
                                                        favoriteRate={favoriteRate ?? 0}
                                                        setFavoriteRate={setFavoriteRate}
                                                        isRateChangable={true}
                                                        cursor='pointer'
                                                    />
                                                </>
                                            )
                                        }
                                    </Flex>

                                    <Flex
                                        className='nft-infos'
                                        direction={'column'}
                                        width={['300px', '350px', '300px', '350px']}
                                        gap={['30px']}
                                    >
                                        <Flex
                                            className="name"
                                            fontFamily={'Poppins'}
                                            fontWeight={'700'}
                                            fontSize={'32px'}
                                            lineHeight={'30px'}
                                            color={'white'}
                                            textTransform={'capitalize'}
                                        >
                                            Name : {nftInfo.name}
                                        </Flex>

                                        <Flex
                                            className='nft-infos'
                                            direction={'column'}
                                            width={['300px', '350px', '300px', '350px']}
                                            justifyContent={'center'}
                                            gap={['30px']}
                                        >
                                            <Flex
                                                className="description"
                                                fontFamily={'Poppins'}
                                                fontWeight={'700'}
                                                fontSize={'22px'}
                                                lineHeight={'30px'}
                                                color={'white'}
                                            >
                                                Description : {nftInfo.description}
                                            </Flex>

                                            <Flex
                                                className="airtst"
                                                fontFamily={'Poppins'}
                                                fontWeight={'700'}
                                                fontSize={'22px'}
                                                lineHeight={'30px'}
                                                color={'white'}
                                                textTransform={'capitalize'}
                                            >
                                                Artist : {nftInfo?.artist}
                                            </Flex>

                                            <Flex
                                                className='nft-category-label'
                                                fontFamily={'Poppins'}
                                                fontWeight={'700'}
                                                fontSize={'22px'}
                                                lineHeight={'30px'}
                                                color={'white'}
                                                textTransform={'capitalize'}
                                            >
                                                Category : {nftInfo?.category}
                                            </Flex>

                                            <Flex
                                                className='nft-sale-status'
                                                fontFamily={'Poppins'}
                                                fontWeight={'700'}
                                                fontSize={'22px'}
                                                lineHeight={'30px'}
                                                color={'white'}
                                                textTransform={'capitalize'}
                                            >
                                                Sale status : {
                                                    nftInfo.tokenSaleStatus == TokenSaleStatus.NotInSale ?
                                                        'Not in sale' : nftInfo.tokenSaleStatus == TokenSaleStatus.InFixedSale ? 'In fixed sale' : 'In auction'
                                                }
                                            </Flex>

                                            <Flex
                                                gap={'5px'}
                                            >
                                                <Flex
                                                    className='nft-owner'
                                                    fontFamily={'Poppins'}
                                                    fontWeight={'700'}
                                                    fontSize={'22px'}
                                                    lineHeight={'30px'}
                                                    color={'white'}
                                                    textTransform={'capitalize'}
                                                >
                                                    Owner :
                                                </Flex>
                                                <Flex
                                                    onClick={() => copy(nftInfo.owner)}
                                                    cursor={'copy'}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={'700'}
                                                    fontSize={'22px'}
                                                    lineHeight={'30px'}
                                                    color={'white'}
                                                    textTransform={'capitalize'}
                                                >
                                                    {nftInfo.owner != user?.publicKey ? getEllipsisTxt(nftInfo.owner) : 'You'}
                                                </Flex>
                                            </Flex>

                                            <Flex
                                                gap={'5px'}
                                            >
                                                <Flex
                                                    className='nft-owner'
                                                    fontFamily={'Poppins'}
                                                    fontWeight={'700'}
                                                    fontSize={'22px'}
                                                    lineHeight={'30px'}
                                                    color={'white'}
                                                    textTransform={'capitalize'}
                                                >
                                                    SC :
                                                </Flex>
                                                <Flex
                                                    onClick={() => copy(nftInfo.owner)}
                                                    cursor={'copy'}
                                                    fontFamily={'Poppins'}
                                                    fontWeight={'700'}
                                                    fontSize={'22px'}
                                                    lineHeight={'30px'}
                                                    color={'white'}
                                                    textTransform={'capitalize'}
                                                >
                                                    <a
                                                        href={`${explorers[chainId]}/address/${nftMintContractAddresses[chainId]}`}
                                                        target='_blank' rel="noopener noreferrer"
                                                        className='underline'
                                                    >
                                                        {`${contractAddress?.slice(0, 6)}...${contractAddress?.slice(-6)}`}
                                                    </a>
                                                </Flex>
                                            </Flex>

                                            {
                                                (nftInfo.price ?? 0) > 0 && (
                                                    <>
                                                        <Flex
                                                            gap={'5px'}
                                                        >
                                                            <Flex
                                                                className='nft-owner'
                                                                fontFamily={'Poppins'}
                                                                fontWeight={'700'}
                                                                fontSize={'22px'}
                                                                lineHeight={'30px'}
                                                                color={'white'}
                                                                textTransform={'capitalize'}
                                                            >
                                                                {
                                                                    nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction ?
                                                                        ((nftInfo.currentBidderCount ?? 0) > 0 ? 'Top Bid Price : ' : 'Start Price : ')
                                                                        : 'Current price : '
                                                                }
                                                            </Flex>
                                                            <Flex
                                                                onClick={() => copy(nftInfo.owner)}
                                                                cursor={'copy'}
                                                                fontFamily={'Poppins'}
                                                                fontWeight={'700'}
                                                                fontSize={'22px'}
                                                                lineHeight={'30px'}
                                                                color={'white'}
                                                                textTransform={'capitalize'}
                                                            >
                                                                {nftInfo.price} {newChainAttrs[chainId].nativeToken}
                                                            </Flex>
                                                        </Flex>
                                                    </>
                                                )
                                            }
                                            {
                                                (nftInfo.lastSalePrice ?? 0) > 0 && (<Flex
                                                    gap={'5px'}
                                                >
                                                    <Flex
                                                        className='nft-owner'
                                                        fontFamily={'Poppins'}
                                                        fontWeight={'700'}
                                                        fontSize={'22px'}
                                                        lineHeight={'30px'}
                                                        color={'white'}
                                                        textTransform={'capitalize'}
                                                    >
                                                        Last Sale Price :
                                                    </Flex>
                                                    <Flex
                                                        onClick={() => copy(nftInfo.owner)}
                                                        cursor={'copy'}
                                                        fontFamily={'Poppins'}
                                                        fontWeight={'700'}
                                                        fontSize={'22px'}
                                                        lineHeight={'30px'}
                                                        color={'white'}
                                                        textTransform={'capitalize'}
                                                    >
                                                        {nftInfo.lastSalePrice} {newChainAttrs[chainId].nativeToken}
                                                    </Flex>
                                                </Flex>)
                                            }
                                            {
                                                (nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction) && (
                                                    <>
                                                        <Flex
                                                            gap={'10px'}
                                                            alignItems={'center'}
                                                        >
                                                            <Flex
                                                                className='nft-owner'
                                                                fontFamily={'Poppins'}
                                                                fontWeight={'700'}
                                                                fontSize={'22px'}
                                                                lineHeight={'30px'}
                                                                color={'white'}
                                                                textTransform={'capitalize'}
                                                            >
                                                                Your Bid:
                                                            </Flex>
                                                            <Flex
                                                                alignItems={'end'}
                                                            >
                                                                <Input
                                                                    value={myBidPrice == null ? '' : myBidPrice}
                                                                    type={'number'}
                                                                    variant="flushed"
                                                                    placeholder='Amount'
                                                                    borderBottom={`2px solid ${secondColor}`}
                                                                    onChange={(e) => setMyBidPrice(e?.target?.value ? Number(e?.target?.value) : null)}
                                                                    fontFamily={'Poppins'}
                                                                    _focusVisible={{
                                                                        boxShadow: 'none',
                                                                    }}
                                                                    color={'white'}
                                                                />
                                                                <Flex
                                                                    fontSize={'22px'}
                                                                    lineHeight={'30px'}
                                                                >
                                                                    {newChainAttrs[chainId].nativeToken}
                                                                </Flex>
                                                            </Flex>

                                                        </Flex>
                                                        <Flex
                                                            fontFamily={'Poppins'}
                                                            fontWeight={'700'}
                                                            fontSize={'22px'}
                                                            lineHeight={'30px'}
                                                            color={'white'}
                                                            textTransform={'capitalize'}
                                                        >
                                                            <Countdown date={(Number(nftInfo.currentAuction?.endTimestamp) ?? 0) * 1000} renderer={CountDownRenderer} />
                                                        </Flex>
                                                    </>
                                                )
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>

                                <Flex
                                    gap={['20px', '20px', '30px',]}
                                    mt={['10px']}
                                    justifyContent={'center'}
                                    alignItems={['center']}
                                    direction={['column', 'column', 'column', 'row']}
                                >
                                    {
                                        nftInfo?.tokenSaleStatus == TokenSaleStatus.InFixedSale && (
                                            <BuyFromFixedSale
                                                tokenId={nftInfo.tokenId}
                                                contractAddress={contractAddress}
                                                price={nftInfo.price ?? 0}
                                                nftObjectId={nftInfo._id}
                                                isDisabled={nftInfo.owner == user?.publicKey}
                                            />
                                        )
                                    }

                                    {
                                        nftInfo?.tokenSaleStatus == TokenSaleStatus.InAuction && (
                                            <MakeBid
                                                tokenId={nftInfo.tokenId}
                                                contractAddress={contractAddress}
                                                myBidPrice={myBidPrice}
                                                currentTopBid={nftInfo.price ?? 0}
                                                nftObjectId={nftInfo._id}
                                                auctionObjectId={nftInfo.currentAuction?._id ?? ''}
                                                isDisabled={nftInfo.owner == user?.publicKey}
                                                getNFTForTokenId={getNFTForTokenId}
                                            />
                                        )
                                    }
                                </Flex>

                                {/* Bidders table */}
                                {
                                    nftInfo?.tokenSaleStatus == TokenSaleStatus.InAuction && (nftInfo?.currentBidderCount || 0) > 0 && (
                                        <Flex mt={'50px'}>
                                            <Table>
                                                <Thead
                                                    fontStyle='normal'
                                                    borderRadius='5px'
                                                >
                                                    <Tr>
                                                        <Th
                                                            color={'white'}
                                                            fontFamily={'Poppins'}
                                                            fontSize={'14px'}
                                                            px={0}
                                                        >
                                                            Bidder Address
                                                        </Th>
                                                        <Th
                                                            color={'white'}
                                                            fontFamily={'Poppins'}
                                                            fontSize={'14px'}
                                                            textAlign={'center'}
                                                            px={0}
                                                        >
                                                            Amount
                                                        </Th>
                                                        <Th
                                                            color={'white'}
                                                            fontFamily={'Poppins'}
                                                            fontSize={'14px'}
                                                            textAlign={'center'}
                                                            px={0}
                                                        >
                                                            Time
                                                        </Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        biddersInfo.map((item, index) => (
                                                            <Tr
                                                                key={index}
                                                                fontFamily={'Poppins'}
                                                                fontWeight={'700'}
                                                                fontSize={'14px'}
                                                                color={'white'}
                                                                borderRadius={'5px'}
                                                            >
                                                                <Td
                                                                    borderColor='transparent'
                                                                    px={0}
                                                                    py={'5px'}
                                                                >
                                                                    {item.bidder}
                                                                </Td>
                                                                <Td
                                                                    borderColor='transparent'
                                                                    textAlign={'center'}
                                                                    px={0}
                                                                    py={'5px'}
                                                                >
                                                                    {item.bidPrice}
                                                                </Td>
                                                                <Td
                                                                    borderColor='transparent'
                                                                    textAlign={'center'}
                                                                    px={0}
                                                                    py={'5px'}
                                                                >
                                                                    {`${getFormattedDateString(new Date(item.createdAt))} ${getFormattedTime(new Date(item.createdAt))}`}
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                    }
                                                </Tbody>
                                            </Table>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex>
                            Okay
                        </Flex>
                    )
                )
            }
        </Flex>
    )
}

export default NFTDetails;
