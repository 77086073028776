import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createConfig } from 'wagmi'
import { avalanche, avalancheFuji, bsc, bscTestnet, polygon, polygonMumbai, arbitrum, arbitrumGoerli } from '@wagmi/core/chains';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';

const isMainnet = process.env.REACT_APP_IS_MAINNET == 'true';

const chains = [
    ...(
        isMainnet
            ?
            [polygon, bsc, avalanche, arbitrum]
            :
            [polygonMumbai, bscTestnet, avalancheFuji, arbitrumGoerli]
    ),
];

export const projectId = '664df1515d4bd775b7d5705b8277ff35';
const infuraApiKey = isMainnet ? '89d41ced25e344139ef864895cc8380f' : 'f39e94027d1045bbb7f3f669a9950c51';

const { publicClient, webSocketPublicClient } = configureChains(
    chains,
    [
        w3mProvider({ projectId }),
        infuraProvider({ apiKey: infuraApiKey }),
        publicProvider()
    ]
);

export const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
    webSocketPublicClient,
});
export const defaultChain = isMainnet ? bsc : bscTestnet;
export const ethereumClient = new EthereumClient(wagmiConfig, chains);