import { Button, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useNetwork } from 'wagmi';
import { disconnect, writeContract, switchNetwork } from '@wagmi/core';
import { secondColor, nftSellContractAddresses, thirdColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { useState, } from 'react';
import AnnounceModal from '../modal/AnnounceModal';
import { checkIconInGreenBg, nftSellContractAbi } from '../../utils/assets';
import { getSignature } from '../../utils/interact';
import axios, { HttpStatusCode } from 'axios';
import { ethers } from 'ethers';
import { useNavigate, useParams } from 'react-router-dom';
import { delay } from '../../utils/formatters';

type BuyFromFixedSaleProps = {
    tokenId: number;
    contractAddress: string | undefined;
    price: number;
    nftObjectId: string | null;
    isDisabled: boolean;
}

const BuyFromFixedSale = ({
    tokenId,
    contractAddress,
    price,
    nftObjectId,
    isDisabled,
}: BuyFromFixedSaleProps) => {
    const navigate = useNavigate();
    const { chainId } = useParams();
    const { chain } = useNetwork();
    const { user } = useChainContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenBuyFromFixedSaleSuccessModal,
        onOpen: onOpenBuyFromFixedSaleSuccessModal,
        onClose: onCloseBuyFromFixedSaleSuccessModal,
    } = useDisclosure();

    const buyFromFixedSale = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'buyFromFixedSale',
                value: ethers.utils.parseEther(price!.toString()).toBigInt(),
                args: [
                    contractAddress,
                    tokenId,
                    signature,
                ],
            });

            console.log("transaction hash: ", tx.hash);

            await axios.post('/fixedSale/buyFromFixedSale', {
                nftAddress: contractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
            });

            await delay(20000);

            onOpenBuyFromFixedSaleSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            console.log("nft buy from fixed sale error:", e);
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                toast.error('Unfortunately, your operation has failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const buy = async () => {
        if (!chain || !user) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (parseInt(chainId ?? '') != chain?.id) {
            await switchNetwork({ chainId: Number(chainId) });
        }
        setIsProcessing(true);
        const signatureResult = await getSignature(
            user!,
            Number(chainId),
            contractAddress!,
            tokenId,
            1, // for compatibility with sign contract
        )
        if (!signatureResult?.isSuccess) {
            toast.error('Signature Error');
            setIsProcessing(false);
            return;
        }

        await buyFromFixedSale(signatureResult.signature);
    }

    return (
        <>
            <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'15px'}
                mt={'30px'}
                onClick={() => buy()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['250px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                isDisabled={isDisabled}
                fontWeight={'900'}
                _hover={{
                    background: secondColor,
                }}
                _selected={{
                    background: secondColor,
                }}
            >
                Buy from Fixed Sale
            </Button>
            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenBuyFromFixedSaleSuccessModal}
                onCloseAnnounceModal={() => {
                    navigate(`../sell`)
                    // onCloseBuyFromFixedSaleSuccessModal();
                }}
                announceText={`You have been successfully bought`}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />
        </>
    )
}

export default BuyFromFixedSale;