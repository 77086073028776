import {
  Link,
  Flex,
} from '@chakra-ui/react'
import { useState, useEffect, } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { secondColor } from '../../utils/config';

type NavlinkProps = {
  href: string
  name: string
  onClose?: () => void
  footer?: boolean
}

export default function NavLink({ name, href, onClose, footer }: NavlinkProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [])

  const doNothing = () => { };

  return (
    <Flex
      color={currentRoute === href ? secondColor : 'white'}
      _focus={{
        border: 'none',
        color: secondColor,
      }}
      _hover={{
        color: secondColor,
        cursor: 'pointer'
      }}
      onClick={() => {
        navigate(href);
        onClose ? onClose() : doNothing();
      }}
      fontSize={footer ? '14px' : width < 1400 ? '16px' : '18px'}
      fontFamily={'Poppins'}
      fontWeight={'bold'}
      textAlign='start'
      width={['unset', 'unset', 'unset', 'unset', 'unset']}
      className='header-navFlex-item-wrapper'
    >
      {name}
    </Flex>
  )
}
