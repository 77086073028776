import { useState } from "react";
import { io } from "socket.io-client";
import { ChainContext, Sockets, } from "./Context";
import { UserType } from "./types";

const ContextProvider = ({ children }: { children: any }) => {
    const [sockets, setSockets] = useState<Sockets>({
        // userSocket: io(`/user`, { transports: ['websocket'] }),
        // nfts: io(`/nft`, { transports: ['websocket'] }),
        // fixedSales: io(`/fixedSale`, { transports: ['websocket'] }),
        // bids: io(`/bid`, { transports: ['websocket'] }),
        // auctions: io(`/auction`, { transports: ['websocket'] }),
        // groups: io(`/group`, { transports: ['websocket'] }),
    })
    const [user, setUser] = useState<UserType | null>(null);
    const [selectedTokenObjectIds, setSelectedTokenObjectIds] = useState<string[]>([]);

    return (
        <ChainContext.Provider
            value={{
                sockets,
                setSockets,
                user,
                setUser,
                selectedTokenObjectIds,
                setSelectedTokenObjectIds,
            }}
        >
            {children}
        </ChainContext.Provider>
    );
};

export default ContextProvider;