import {
    Button,
    Flex,
    Grid,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState, } from 'react';
import { bscChainId, bscTestnetChainId, firstColor, secondColor, thirdColor, } from '../../../utils/config';
import { GroupInfo, NftType, TokenType } from '../../../utils/types';
import { useChainContext } from '../../../utils/Context';
import { toast } from 'react-toastify';
import { getFullUrl } from '../../../utils/formatters';
import CreateGroupModal from '../../modal/CreateGroupModal';
import { useNavigate } from 'react-router-dom';
import AnnounceModal from '../../modal/AnnounceModal';

type MyGroupsProps = {
}

const MyGroups = ({
}: MyGroupsProps) => {
    const navigate = useNavigate();
    const {
        user,
        sockets,
        selectedTokenObjectIds,
        setSelectedTokenObjectIds,
    } = useChainContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myGroups, setMyGroups] = useState<GroupInfo[]>([]);
    const [width, setWidth] = useState(0);
    const {
        isOpen: isOpenCreateGroupModal,
        onOpen: onOpenCreateGroupModal,
        onClose: onCloseCreateGroupModal,
    } = useDisclosure();

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        if (!sockets.groups) return;

        sockets.groups.on("newGroupNotify", (newGroup: GroupInfo) => {
            getMyGroups();
        });

        sockets.groups.on("addToGroupSuccessNotify", (group: GroupInfo) => {
            if (user && group.user.toString() == user._id.toString()) {
                getMyGroups();
            }
        });
    }, [sockets, user]);

    useEffect(() => {
        (async () => {
            if (user) {
                setIsLoading(true);
                await getMyGroups();
                setIsLoading(false);
            }
        })()
    }, [user])

    const getMyGroups = async () => {
        try {
            const myGroups: GroupInfo[] = (await axios.get(`/group/${user?._id}`)).data;
            console.log("==== my groups: ==== ", myGroups);
            //@ts-ignore
            setMyGroups(myGroups);
        } catch (e: any) {
            console.log('error in getting my groups: ', e);
            toast.error('Unfortunately, your operation has failed: ', e);
        }
    }

    const handleAddItemsToGroup = async (groupIndex: number) => {
        if (selectedTokenObjectIds.length == 0) {
            toast.error('Please confirm checkbox to add nfts or sfts to the group');
            return;
        }

        try {
            const result = (await axios.post('/group/addToGroup', {
                tokenObjectIds: selectedTokenObjectIds,
                groupIndex,
            })).data;
            console.log({ result });
            setSelectedTokenObjectIds([]);
        } catch (e) {
            console.log("Error happened in adding items to the group: ", e);
        }
    }

    const createGroupHandle = async (groupInfo: GroupInfo) => {
        const newGroups = myGroups;
        newGroups.push(groupInfo);
        setMyGroups(newGroups);
    }

    return (
        <Flex
            className='group-tokens'
            direction={['column']}
            mt={['10px']}
            mb={['50px']}
        >
            <Flex
                direction={'column'}
            >
                <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'23px'}
                    lineHeight={'30px'}
                    color={secondColor}
                    textTransform={'capitalize'}
                    mb={['10px']}
                >
                    My Groups
                </Flex>

                <Flex
                    justifyContent={'right'}
                    direction={['column', 'row']}
                    gap={['0px', '20px']}
                    alignItems={'center'}
                >
                    <Button
                        border={`1px solid ${secondColor}`}
                        borderRadius={'45px'}
                        px={'28px'}
                        py={'7px'}
                        my={['5px', '15px']}
                        onClick={onOpenCreateGroupModal}
                        fontFamily={'Poppins'}
                        fontSize={'16px'}
                        lineHeight={'19px'}
                        cursor={'pointer'}
                        width={['200px']}
                        height={['45px']}
                        background={thirdColor}
                        color={'white'}
                        fontWeight={'900'}
                        _hover={{
                            background: secondColor,
                        }}
                        _selected={{
                            background: secondColor,
                        }}
                    >
                        Create Group
                    </Button>

                    {
                        (myGroups.length > 0) && (
                            <Flex
                                alignItems='center'
                                className='group-select-dropdown-wrapper'
                            >
                                <Menu
                                >
                                    <MenuButton
                                        as={Button}
                                        px='0'
                                        backgroundColor='transparent'
                                        _hover={{
                                            backgroundColor: 'transparent',
                                        }}
                                        _focus={{
                                            backgroundColor: 'transparent',
                                        }}
                                        _active={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <Flex
                                            direction='row'
                                            alignSelf='center'
                                            alignItems='center'
                                        >
                                            <Flex
                                                border={`1px solid ${secondColor}`}
                                                justifyContent={['center']}
                                                alignItems={['center']}
                                                borderRadius={'45px'}
                                                px={'28px'}
                                                py={'7px'}
                                                my={'15px'}
                                                fontFamily={'Poppins'}
                                                fontSize={'16px'}
                                                lineHeight={'19px'}
                                                cursor={'pointer'}
                                                width={['200px']}
                                                height={['45px']}
                                                background={thirdColor}
                                                color={'white'}
                                                fontWeight={'900'}
                                                _hover={{
                                                    background: secondColor,
                                                }}
                                                _selected={{
                                                    background: secondColor,
                                                }}
                                            >
                                                Add items to Group
                                            </Flex>
                                        </Flex>
                                    </MenuButton>
                                    <MenuList
                                        backgroundColor={firstColor}
                                        minWidth='min-content'
                                        width='200px'
                                        zIndex={'3'}
                                    >
                                        {
                                            myGroups.map((item, index) => (
                                                <MenuItem
                                                    _hover={{
                                                        backgroundColor: secondColor,
                                                    }}
                                                    _focus={{
                                                        backgroundColor: secondColor,
                                                    }}
                                                    onClick={() => handleAddItemsToGroup(index)}
                                                    key={index}
                                                    fontFamily='Poppins'
                                                    fontStyle='normal'
                                                    fontWeight='700'
                                                    fontSize='16px'
                                                    lineHeight='16px'
                                                    paddingLeft='15px'
                                                    background={firstColor}
                                                >
                                                    <Text
                                                        textTransform='capitalize'
                                                        color={'white'}
                                                    >
                                                        {`Add itmes to ${item.groupName}`}
                                                    </Text>
                                                </MenuItem>
                                            ))
                                        }
                                    </MenuList>
                                </Menu>
                                {/* <AnnounceModal
                            isOpenAnnounceModal={isOpenChainSwitchModal}
                            onCloseAnnounceModal={() => onCloseChainSwitchModal()}
                            announceText={`Are you going to switch chain to ${currentMainnetOrTestnetAttrs[chainIndex]?.name} ?`}
                            announceLogo={exclamationIconInRedBg}
                            announceModalButtonText={'Switch Chain'}
                            announceModalButtonAction={() => {
                                if (address) {
                                    switchNetwork?.(currentMainnetOrTestnetAttrs[chainIndex].chainId);
                                } else {
                                    toast.error('Please login your wallet at first');
                                }
                                onCloseChainSwitchModal();
                            }}
                        /> */}
                            </Flex >
                        )
                    }
                </Flex>

            </Flex>


            {
                isLoading && (
                    <Flex
                        className="loading-container"
                        justifyContent={'center'}
                        mt={'50px'}
                        alignItems={'center'}
                    >
                        <section>
                            <Flex className="loader loader-1">
                                <Flex className="loader-outter" />
                                <Flex className="loader-inner" />
                            </Flex>
                        </section>
                    </Flex>
                )
            }
            {
                !isLoading && (
                    <Grid
                        templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(3, 1fr)',
                            width < 1992 ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)',
                        ]}
                        gap={['20px', '20px', '10px', '41px']}
                        mt={['10px', '20px', '30px', '35px']}
                    >
                        {
                            myGroups && myGroups.map((item: GroupInfo, index: number) => (
                                <Flex
                                    key={index}
                                    justifyContent={['center']}
                                    // width={['320px']}
                                    width={['100%']}
                                    height={['320px']}
                                    position={'relative'}
                                    className={`group-${index}`}
                                    cursor={'pointer'}
                                >
                                    <Grid
                                        templateColumns={[
                                            'repeat(3, 3fr)',
                                        ]}
                                    >
                                        {
                                            item.tokens && item.tokens.slice(0, 8).map((token: any, index: number) => (
                                                <Flex
                                                    key={index}
                                                >
                                                    <Image
                                                        src={getFullUrl(process.env.REACT_APP_PINATA_GATEWAY_URL ?? '', token.imageUrl)}
                                                        width={['106.66px']}
                                                        height={['106.66px']}
                                                        opacity={'0.2'}
                                                    />
                                                </Flex>
                                            ))
                                        }
                                    </Grid>
                                    <Flex
                                        width={['320px']}
                                        height={['320px']}
                                        background={'rgba(255, 255, 255, 0.1)'}
                                        color={'white'}
                                        position={'absolute'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        fontFamily={'Poppins'}
                                        fontSize={'30px'}
                                        onClick={() => navigate(`/my-nfts/group/${item.groupIndex}`)}
                                    >
                                        {item.groupName}
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Grid>
                )
            }
            <CreateGroupModal
                isOpenCreateGroupModal={isOpenCreateGroupModal}
                onCloseCreateGroupModal={onCloseCreateGroupModal}
                myGroups={myGroups}
                createGroupHandle={createGroupHandle}
            />
        </Flex>
    )
}

export default MyGroups
