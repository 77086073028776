export type ChainAttrItemType = {
    chainId: number,
    logo: string,
    name: string,
    mainnetName: string,
    alt: string,
    nativeToken: string,
}

export type UserType = {
    _id: string,
    publicKey: string,
    username: string,
    isAdmin: boolean,
    lastMessageAt: number,
    email: string,
    phone: string,
    country: string,
    address: string,
    createdAt: string,
    updatedAt: string,
    signInType: string,
}

export type ArtistType = {
    _id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
}

export type CategoryType = {
    _id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
}

export type NftType = {
    _id: string,
    chainId: number,
    tokenId: number,
    contractAddress: string,
    tokenType: TokenType,
    saleId?: number,
    amount?: number,
    totalSupply: number,
    name: string,
    description: string,
    owner: string,
    owners: NftOwnerInfo[],
    artist?: string,
    category?: string,
    tokenSaleStatus?: TokenSaleStatus,
    currentAuction?: AuctionType,
    currentBidderCount?: number,
    imageUrl: string,
    metadataUrl: string,
    price?: number,
    lastListedTimestamp?: number,
    lastSoldTimestamp?: number,
    lastSalePrice?: number,
    totalFavoriteCount?: number,
    totalFavoriteUsers?: number,
    favoriteRate?: number,
    createdAt?: string,
    updatedAt?: string,
    isValid: boolean,
}

export type FixedSaleType = {
    _id: string,
    nft: NftType,
    seller: UserType,
    buyer: UserType | null,
    isPriceNativeToken: boolean,
    price: number,
    status: SaleStatus,
    createdAt: string,
    updatedAt: string,
}

export type ActivityType = {
    _id: string,
    nft: NftType,
    chainId: number,
    type: string, // "Transfer", "Mint", "ListFixedSale", "CancelFixedSale", ...
    status: string, // "pending", "confirmed"
    from: UserType,
    to: UserType,
    isPriceNativeToken?: boolean,
    price?: number,
    createdAt: string,
    updatedAt: string,
}

export type FavoriteType = {
    _id: string,
    user: UserType,
    token: string,
    favoriteRate: number,
    createdAt: string,
    updatedAt: string,
}

export type AuctionType = {
    _id: string,
    nft: NftType,
    seller: string,
    chainId: number,
    tokenId: number,
    buyer: string,
    isPriceNativeToken: boolean,
    startPrice: number,
    startTimestamp: number,
    endTimestamp: number,
    status: SaleStatus,
    topBidder: string,
    topBidPrice: number,
    createdAt: string,
    updatedAt: string,
}

export type BidType = {
    _id: string,
    nft: NftType,
    auction: AuctionType,
    bidPrice: number,
    bidder: UserType,
    isWon: boolean,
    isWithdrawn: boolean,
    createdAt: string,
    updatedAt: string,
}

export type BidderInfoType = {
    _id: string,
    nft: NftType,
    auction: AuctionType,
    bidPrice: number,
    bidder: string,
    isWon: boolean,
    isWithdrawn: boolean,
    createdAt: string,
    updatedAt: string,
}

export enum SaleStatus {
    On,
    Cancelled,
    Sold,
    Off,
}

export enum TokenSaleStatus {
    NotInSale,
    InFixedSale,
    InAuction,
}

export enum ChainStatus {
    All,
    Bsc,
    Polygon,
    Avalanche,
    Arbitrum,
}

export enum TokenStatus {
    Any,
    FixedSale,
    Auction,
    New,
    HasOffers,
}

export enum OwnerStatus {
    All,
    Me,
}

export enum TextStatus {
    FindByName,
    FindByArtist,
    FindByCategory,
    FindByDescription,
    None,
}

export enum SortStatus {
    PriceLow2High,
    PriceHigh2Low,
    RecentlyListed,
    MostFavorite,
    RecentlySold,
    RecentlyCreated,
}

export type DestinationWalletInfoType = {
    address: string,
    amount: string,
}

export enum TokenType {
    NFT,
    SFT,
}

export type NftOwnerInfo = {
    address: string,
    balance: number,
}

export type GroupInfo = {
    user: string,
    tokens: {
        tokenObjectId: string,
        imageUrl: string
    }[],
    groupIndex: number,
    groupName: string,
}


export type CheckType = {
    tokenId: number,
    contractAddress: string,
    isChecked: boolean
}
