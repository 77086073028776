import {
    Flex,
    Grid,
} from '@chakra-ui/react'
import { useEffect, useState, } from 'react';
import { NftType, TokenType } from '../../utils/types';
import NftCard from '../Card/NftCard';
import SftCard from '../Card/SftCard';

type ResultTokensProps = {
    resultTokens: (NftType)[],
    isLoading: boolean,
}

const ResultTokens = ({
    resultTokens,
    isLoading,
}: ResultTokensProps) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(window.innerWidth);
        console.log(resultTokens);
    }, []);


    return (
        <>
            {
                isLoading && (
                    <Flex
                        className="loading-container"
                        justifyContent={'center'}
                        mt={'100px'}
                        alignItems={'center'}
                    >
                        <section>
                            <Flex className="loader loader-1">
                                <Flex className="loader-outter" />
                                <Flex className="loader-inner" />
                            </Flex>
                        </section>
                    </Flex>
                )
            }
            {
                !isLoading && (
                    <Grid
                        templateColumns={[
                            'repeat(1, 1fr)',
                            'repeat(1, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(2, 1fr)',
                            'repeat(3, 1fr)',
                            width < 1992 ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)',
                        ]}
                        gap={['20px', '20px', '30px', '41px']}
                        mt={['10px', '20px', '30px', '35px']}
                    >
                        {
                            resultTokens && resultTokens.map((item: NftType, index: number) => (
                                <Flex
                                    key={index}
                                    justifyContent={'center'}
                                >
                                    {
                                        item.tokenType == TokenType.NFT ? (
                                            <NftCard
                                                nftInfo={item}
                                            />
                                        ) : (
                                            <SftCard
                                                nftInfo={item}
                                            />
                                        )
                                    }
                                </Flex>
                            ))
                        }
                    </Grid>
                )
            }
        </>
    )
}

export default ResultTokens
