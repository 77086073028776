import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Web3Modal } from '@web3modal/react';
import { WagmiConfig } from 'wagmi';
import Layout from './layouts/layout';
import NFTDetails from './pages/NFTDetails';
import ListForSale from './pages/ListForSale';
import SFTDetails from './pages/SFTDetails';
import SFTListForSale from './pages/SFTListForSale';
import Group from './pages/Group';
import { linkArray } from './utils/config';
import { defaultChain, ethereumClient, projectId, wagmiConfig } from './utils/wagmi';
import { arbitrumLogo, avalancheLogo, bscLogo, polygonLogo } from './utils/assets';

function App() {
  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Layout />} >
              {
                linkArray.map((item: any, index: number) => (
                  <Route
                    path={item.href}
                    key={'route-key-' + index}
                    element={<item.component />}
                  />
                ))
              }
              <Route path='/nft/:contractAddress/:chainId/:tokenId/detail' element={<NFTDetails />} />
              <Route path='/nft/:contractAddress/:chainId/:tokenId/sell' element={<ListForSale />} />
              {/* <Route path='/sft/:contractAddress/:chainId/:tokenId' element={<SFTDetails />} />
              <Route path='/sft/:contractAddress/:chainId/:tokenId/sell' element={<SFTListForSale />} /> */}
              <Route path='/my-nfts/group/:groupIndex/' element={<Group />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </WagmiConfig>

      <Web3Modal
        themeMode='light'
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={defaultChain}
        chainImages={{
          '43113': avalancheLogo,
          '97': bscLogo,
          '80001': polygonLogo,
          '421613': arbitrumLogo
        }}
      />

      <ToastContainer
        autoClose={5000}
        hideProgressBar={true}
        toastStyle={{
          top: '60px',
          fontSize: '20px'
        }}
      />
    </div>
  );
}

export default App;
