import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import axios, { HttpStatusCode } from 'axios';
import { useState, } from 'react';
import { toast } from 'react-toastify';
import { secondColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { GroupInfo } from '../../utils/types';

export type RenameGroupModalProps = {
  isOpenRenameGroupModal: boolean,
  onCloseRenameGroupModal(): void,
  groupIndex: number,
}

const RenameGroupModal = ({
  isOpenRenameGroupModal,
  onCloseRenameGroupModal,
  groupIndex,
}: RenameGroupModalProps) => {
  const {
    user,
  } = useChainContext();
  const [groupName, setGroupName] = useState<string>('');

  const handleRenameGroupName = async () => {
    if (!groupName) {
      toast.error('Please type group name at first');
      return;
    }

    try {
      const updatedGroup: GroupInfo = (await axios.post(`/group/reanmeGroupName`, {
        groupName,
        groupIndex,
      })).data;
      toast.success('Group name updated');
      onCloseRenameGroupModal();
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
      } else {
        console.log("error in handling favorite rate: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
    }
  }

  return (
    <Modal isOpen={isOpenRenameGroupModal} onClose={onCloseRenameGroupModal}>
      {' '}
      <ModalOverlay
        bg='blackAlpha.10'
        backdropFilter='blur(10px) hue-rotate(10deg)'
      />
      <ModalContent
        border={'1px solid white'}
        borderRadius={'15px'}
      >
        <ModalCloseButton />
        <ModalBody>
          <Flex
            justifyContent={'center'}
            direction={'column'}
            alignItems={'center'}
          >
            <Flex
              width={'100%'}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'25px'}
                lineHeight={'34px'}
                color={'white'}
                textTransform={'capitalize'}
                textAlign={'center'}
              >
                Rename Group Name
              </Flex>
            </Flex>

            <Flex
              gap={['20px']}
              mt={['80px']}
            >
              <Flex
                width={['150px']}
                alignItems={['center']}
              >
                Group Name
              </Flex>

              <Input
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
              />
            </Flex>

            <Button
              onClick={handleRenameGroupName}
              mt={'30px'}
              mb={'15px'}
              padding={['5px 40px']}
              height={'46px'}
              minWidth={'155px'}
              width={'fit-content'}
              background={'unset'}
              borderRadius={'34px'}
              border={`1px solid ${secondColor}`}
              fontSize={'18px'}
              fontFamily={'Poppins'}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              Rename Group Name
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RenameGroupModal
