import {
  Button,
  Flex,
  Grid,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import axios, { HttpStatusCode } from 'axios';
import { useEffect, useState, } from 'react';
import { useNetwork } from 'wagmi';
import ResultTokens from '../../components/Home/ResultTokens'
import { bscChainId, bscTestnetChainId, chainStatuses, firstColor, newChainAttrs, tokenStatuses, ownerStatuses, pageItems, secondColor, sortStatuses, textStatuses, thirdColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { ChainStatus, TokenStatus, NftType, OwnerStatus, SortStatus, TextStatus } from '../../utils/types';
import { BsFilter, } from 'react-icons/bs';
import { IoMdArrowDropdown } from 'react-icons/io'
import SearchComponent from '../../components/Home/SearchComponent';
import { toast } from 'react-toastify';
import { disconnect } from 'wagmi/actions';

const Home = () => {
  const { chain, } = useNetwork();
  const [chainId, setChainId] = useState<number>(process.env.REACT_APP_IS_MAINNET === 'false' ? bscTestnetChainId : bscChainId);
  const {
    user,
    sockets,
  } = useChainContext();
  const [resultTokens, setResultTokens] = useState<(NftType)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilteringOptions, setShowFilteringOptions] = useState<boolean>(true);;
  const [chainStatus, setChainStatus] = useState<ChainStatus>(ChainStatus.All);
  const [tokenStatus, setTokenStatus] = useState<TokenStatus>(TokenStatus.Any);
  const [ownerStatus, setOwnerStatus] = useState<OwnerStatus>(OwnerStatus.All);
  const [textStatus, setTextStatus] = useState<TextStatus>(TextStatus.FindByName);
  const [sortStatus, setSortStatus] = useState<SortStatus>(SortStatus.RecentlyCreated);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [maxPrice, setMaxPrice] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [tokenCount, setTokenCount] = useState<number>(0);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const getResultTokens = async (page?: number) => {
    try {
      setIsLoading(true);

      const resultTokens = (await axios.post(`/token/search`, {
        chainStatus,
        tokenStatus,
        ownerStatus,
        ownerAddress: user == null ? null : user?.publicKey,
        textStatus,
        text: searchText,
        sortStatus,
        minPrice,
        maxPrice,
        page: page ? page : 0,
      })).data;

      if (resultTokens.isSuccess) {
        if (page) {
          setResultTokens((previousTokens) => [...previousTokens, ...resultTokens.slicedTokens]);
        } else {
          setResultTokens(resultTokens.slicedTokens);
        }
        setTokenCount(resultTokens.count);
      } else {
        console.log(resultTokens.errorMessage);
      }
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
        disconnect();
      } else {
        console.log("error in getting result tokens: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
    }
    setIsLoading(false);
  }

  const handleClearFilter = () => {
    setChainStatus(ChainStatus.All);
    setTokenStatus(TokenStatus.Any);
    setOwnerStatus(OwnerStatus.All);
  };

  useEffect(() => {
    setPage(0);
    getResultTokens();
  }, [
    chainStatus,
    tokenStatus,
    ownerStatus,
    sortStatus,
  ])

  useEffect(() => {
    if (page > 0) {
      getResultTokens(page);
    }
  }, [
    page,
  ])

  useEffect(() => {
    if (!sockets.nfts || !sockets.sfts) return;

    sockets.nfts.on("updatedNftInfo", (nft: NftType) => {
      getResultTokens(page);
    });
  }, [sockets]);

  useEffect(() => {
    setSearchText('');
  }, [textStatus])

  return (
    <Flex
      className='home-page-wrapper-component'
      width={'100%'}
      px={['10px', '40px', '50px']}
      py={['50px', '50px', '100px']}
    >
      <Flex
        className='home-page-component'
        direction={'column'}
        width={'100%'}
      >
        <Flex
          className='chain-tokens'
          direction={['column']}
          mt={['10px']}
          mb={['20px']}
          gap={['30px', '30px', '50px']}
        >
          <Flex
            fontFamily={'Poppines'}
            fontWeight={'700'}
            fontSize={'23px'}
            lineHeight={'30px'}
            color={'white'}
            textTransform={'capitalize'}
          >
            All NFTs and SFTs
          </Flex>

          <Flex
            className='control-component'
            direction={['column']}
          >
            <Flex
              className='control-main-component'
              direction={['row']}
              gap={['10px', '10px', '10px', '60px', '140px']}
              alignItems={['center']}
              fontFamily={['Poppins']}
              fontSize={['16px', '20px']}
              justifyContent={['space-between']}
            >
              <Flex
                className='control-details-switch-button'
                onClick={() => setShowFilteringOptions(!showFilteringOptions)}
                alignItems={['center']}
                bg={showFilteringOptions ? thirdColor : secondColor}
                color={showFilteringOptions ? 'white' : firstColor}
                cursor={'pointer'}
                width={['70px', '90px']}
                height={['35px']}
                borderRadius={['7px']}
                justifyContent={['center']}
                gap={['6px', '10px']}
                px={['6px', '30px']}
              >
                <Flex>
                  <BsFilter />
                </Flex>
                <Flex>
                  Filter
                </Flex>
              </Flex>

              <Flex
                className='result-count'
                whiteSpace={['nowrap']}
                width={['85px']}
                fontSize={['16px']}
                color={'white'}
              >
                {`${tokenCount} Results`}
              </Flex>

              <Flex
                display={['none', 'none', 'none', 'flex']}
                width={['100%']}
                justifyContent={['center']}
              >
                <SearchComponent
                  textStatus={textStatus}
                  setTextStatus={setTextStatus}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  getResultTokens={getResultTokens}
                />
              </Flex>

              <Flex
                className='sort-by-component'
              >
                <Flex
                  border={[`1px solid ${secondColor}`]}
                  borderRadius={['10px']}
                >
                  <Menu
                  >
                    <MenuButton
                      as={Button}
                      px='0'
                      backgroundColor='transparent'
                      _hover={{
                        backgroundColor: 'transparent',
                      }}
                      _focus={{
                        backgroundColor: 'transparent',
                      }}
                      _active={{
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Flex>
                        <Flex
                          direction='row'
                          alignSelf='center'
                          alignItems='center'
                          color={['white']}
                          px={['5px', '10px', '15px', '20px']}
                          width={['150px', '150px', '160px', '170px',]}
                        >
                          {sortStatuses[sortStatus].label}
                        </Flex>
                        <Flex>
                          <Flex
                            direction='column'
                            justifyContent='center'
                            px={['5px', '10px']}
                          >
                            <IoMdArrowDropdown
                              color='white'
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                    </MenuButton>
                    <MenuList
                      backgroundColor={firstColor}
                      minWidth='min-content'
                      width='170px'
                      zIndex={'3'}
                    >
                      {
                        sortStatuses.map((item, index) => (
                          <MenuItem
                            _hover={{
                              backgroundColor: secondColor,
                            }}
                            _focus={{
                              backgroundColor: secondColor,
                            }}
                            onClick={() => {
                              setSortStatus(item.value);
                            }}
                            key={index}
                            fontFamily='Poppins'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize='16px'
                            lineHeight='16px'
                            paddingLeft='15px'
                            background={firstColor}
                            color='white'
                          >
                            <Text
                            >
                              {item.label}
                            </Text>
                          </MenuItem>
                        ))
                      }
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              display={['flex', 'flex', 'flex', 'none']}
              alignItems={['center']}
              mt={['20px']}
              width={['100%']}
            >
              <SearchComponent
                textStatus={textStatus}
                setTextStatus={setTextStatus}
                searchText={searchText}
                setSearchText={setSearchText}
                getResultTokens={getResultTokens}
              />
            </Flex>
            <Flex
              className='control-details-component'
              display={showFilteringOptions ? 'flex' : 'none'}
              mt={['20px']}
              direction={['column', 'column', 'row']}
            >
              <Grid
                gridTemplateColumns={[
                  'repeat(2, 1fr)',
                  'repeat(2, 1fr)',
                  'repeat(4, 1fr)',
                  'repeat(4, 1fr)',
                  'repeat(4, 1fr)',
                  'repeat(6, 1fr)',
                ]}
                gap='20px'
                width={'100%'}
                mt='50px'
              >
                {/* Chain Status */}
                <Flex
                  justifyContent={['center']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    lineHeight={'30px'}
                    color={'white'}
                    className='chain-status'
                    direction={['column']}
                  >
                    <Flex
                    >
                      Chain Status
                    </Flex>

                    <RadioGroup
                      mt={'10px'}
                      onChange={e => setChainStatus(parseInt(e))}
                      value={chainStatus.toString()}
                    >
                      <Stack direction={['column']}>
                        {
                          chainStatuses.map((item, index) => (
                            <Radio
                              key={index}
                              value={item.value.toString()}
                            >
                              {item.label}
                            </Radio>
                          ))
                        }
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>

                {/* Sale Status */}
                <Flex
                  justifyContent={['center']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    lineHeight={'30px'}
                    color={'white'}
                    className='token-status'
                    direction={['column']}
                  >
                    <Flex
                    >
                      Status
                    </Flex>

                    <RadioGroup
                      mt={'10px'}
                      onChange={e => setTokenStatus(parseInt(e))}
                      value={tokenStatus.toString()}
                    >
                      <Stack direction={['column']}>
                        {
                          tokenStatuses.map((item, index) => (
                            <Radio
                              key={index}
                              value={item.value.toString()}
                            >
                              {item.label}
                            </Radio>
                          ))
                        }
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>

                {/* Owner Status */}
                <Flex
                  justifyContent={['center']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    lineHeight={'30px'}
                    color={'white'}
                    className='owner-status'
                    direction={['column']}
                  >
                    <Flex
                    >
                      Owner Status
                    </Flex>

                    <RadioGroup
                      mt={'10px'}
                      onChange={e => setOwnerStatus(parseInt(e))}
                      value={ownerStatus.toString()}
                    >
                      <Stack direction={['column']}>
                        {
                          ownerStatuses.map((item, index) => (
                            <Radio
                              key={index}
                              value={item.value.toString()}
                            >
                              {item.label}
                            </Radio>
                          ))
                        }
                      </Stack>
                    </RadioGroup>
                  </Flex>
                </Flex>

                {/* Price Range */}
                <Flex
                  justifyContent={['center']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    lineHeight={'30px'}
                    color={'white'}
                    className='price-range'
                    direction={['column']}
                    gap={['10px']}
                  >
                    <Flex
                    >
                      Price Range
                    </Flex>

                    {/* <Flex
                      className='token-name'
                      fontSize={'16px'}
                    >
                      {`Token :  ${newChainAttrs[chainId].nativeToken}`}
                    </Flex> */}

                    <Flex
                      className='min-max-value'
                      alignItems={'baseline'}
                      justifyContent={['space-between']}
                      gap={['5px']}
                    >
                      <Input
                        value={minPrice == null ? '' : minPrice}
                        type={'number'}
                        variant="flushed"
                        placeholder='Min'
                        borderBottom={`2px solid ${secondColor}`}
                        onChange={(e) => setMinPrice(e?.target?.value ? Number(e?.target?.value) : null)}
                        fontFamily={'Poppins'}
                        _focusVisible={{
                          boxShadow: 'none',
                        }}
                        _placeholder={{
                          textAlign: 'center'
                        }}
                        width={['35px', '50px']}
                      />

                      <Flex
                        fontSize={'16px'}
                      >
                        To
                      </Flex>

                      <Input
                        value={maxPrice == null ? '' : maxPrice}
                        type={'number'}
                        variant="flushed"
                        placeholder='Max'
                        borderBottom={`2px solid ${secondColor}`}
                        onChange={(e) => setMaxPrice(e?.target?.value ? Number(e?.target?.value) : null)}
                        fontFamily={'Poppins'}
                        _focusVisible={{
                          boxShadow: 'none',
                        }}
                        _placeholder={{
                          textAlign: 'center'
                        }}
                        width={['35px', '50px']}
                      />
                    </Flex>

                    <Flex
                      justifyContent={['center']}
                    >
                      <Flex
                        onClick={() => getResultTokens()}
                        border={['1px solid white']}
                        borderRadius={['5px']}
                        p={['2px 15px']}
                        fontSize={['15px', '15px', '20px']}
                        cursor={'pointer'}
                      >
                        Apply
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* Clear filter */}
                <Flex
                  justifyContent={['center']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'24px'}
                    lineHeight={'30px'}
                    color={'white'}
                    className='owner-status'
                    direction={['column']}
                  >
                    <Button
                      border={`2px solid ${secondColor}`}
                      borderRadius={'45px'}
                      size={'sm'}
                      px={'15px'}
                      py={'10px'}
                      fontFamily={'Poppins'}
                      fontSize={'16px'}
                      lineHeight={'19px'}
                      cursor={'pointer'}
                      background={'none'}
                      color={'white'}
                      fontWeight={'900'}
                      _hover={{
                        background: secondColor,
                      }}
                      _selected={{
                        background: secondColor,
                      }}
                      onClick={() => handleClearFilter()}
                    >
                      Clear Filter
                    </Button>
                  </Flex>
                </Flex>
              </Grid>

            </Flex>
          </Flex>

          {
            (<ResultTokens
              resultTokens={resultTokens}
              isLoading={isLoading}
            />)
          }

          <Flex
            justifyContent={['center']}
            display={tokenCount > pageItems * (page + 1) ? 'flex' : 'none'}
          >
            <Button
              border={`1px solid ${secondColor}`}
              borderRadius={'45px'}
              px={'28px'}
              py={'7px'}
              mt={'30px'}
              onClick={() => setPage(page + 1)}
              fontFamily={'Poppins'}
              fontSize={'20px'}
              lineHeight={'19px'}
              cursor={'pointer'}
              width={['300px', '350px', '350px', '400px']}
              background={'transparent'}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              Load More
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex >
  )
}

export default Home
