import {
    Flex,
    Grid,
} from '@chakra-ui/react'
import axios from 'axios';
import { useEffect, useState, } from 'react';
import { useNetwork, } from 'wagmi';
import { secondColor } from '../../utils/config';
import { NftType, TokenType, CheckType } from '../../utils/types';
import { useChainContext } from '../../utils/Context';
import NftCard from '../../components/Card/NftCard';
import { toast } from 'react-toastify';
import SftCard from '../../components/Card/SftCard';
import MyGroups from '../../components/MyNfts/MyGroups';
import { CheckIcon } from '@chakra-ui/icons';

const MyTokens = () => {
    const { chain, } = useNetwork();
    const {
        user,
        sockets,
    } = useChainContext();
    const [myTokens, setMyTokens] = useState<(NftType)[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        if (!sockets.nfts || !sockets.sfts) return;

        sockets.nfts.on("updatedNftInfo", (nft: NftType) => {
            getMyTokens();
        });
    }, [sockets]);

    const getMyTokens = async () => {
        try {
            const myTokens = (await axios.post(`/token/user`)).data;
            const filteredTokens = myTokens
                .filter((item: any) => item != null)
                .filter((item: any) => item.imageUrl != null && item.name != null)
                .sort((item1: any, item2: any) => item1.createdAt - item2.createdAt);

            console.log("==== my tokens: ==== ", filteredTokens);

            setMyTokens(filteredTokens);
        } catch (e: any) {
            console.log('error in getting my tokens: ', e);
            toast.error('Unfortunately, your operation has failed: ', e);
        }
    }

    useEffect(() => {
        (async () => {
            if (user) {
                setIsLoading(true);
                await getMyTokens();
                setIsLoading(false);
            }
        })()
    }, [user])

    return (
        <Flex
            className='my-tokens-page-wrapper-component'
            width={'100%'}
            px={['10px', '40px', '50px']}
            py={['50px', '50px', '100px']}
        >
            {
                user && (
                    <Flex
                        className='my-tokens-page-component'
                        direction={'column'}
                        width={'100%'}
                    >
                        <MyGroups />

                        <Flex
                            className='chain-tokens'
                            direction={['column']}
                            mt={['10px']}
                            mb={['20px']}
                            gap={['30px', '30px', '100px']}
                        >
                            <Flex
                                fontFamily={'Poppins'}
                                fontWeight={'700'}
                                fontSize={'23px'}
                                lineHeight={'30px'}
                                color={secondColor}
                                textTransform={'capitalize'}
                            >
                                My NFTs and SFTs
                            </Flex>

                            {
                                isLoading && (
                                    <Flex
                                        className="loading-container"
                                        justifyContent={'center'}
                                        mt={'100px'}
                                        alignItems={'center'}
                                    >
                                        <section>
                                            <Flex className="loader loader-1">
                                                <Flex className="loader-outter" />
                                                <Flex className="loader-inner" />
                                            </Flex>
                                        </section>
                                    </Flex>
                                )
                            }
                            {
                                !isLoading && (
                                    <Grid
                                        templateColumns={[
                                            'repeat(1, 1fr)',
                                            'repeat(1, 1fr)',
                                            'repeat(2, 1fr)',
                                            'repeat(2, 1fr)',
                                            'repeat(3, 1fr)',
                                            width < 1992 ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)',
                                        ]}
                                        gap={['20px', '20px', '10px', '41px']}
                                        mt={['10px', '20px', '30px', '35px']}
                                    >
                                        {
                                            myTokens && myTokens.map((item, index) => (
                                                <Flex
                                                    key={index}
                                                    justifyContent={'center'}
                                                >
                                                    {
                                                        item.tokenType == TokenType.NFT ? (
                                                            <NftCard
                                                                nftInfo={item}
                                                                shouldShowCheckbox={true}
                                                            />
                                                        ) : (
                                                            <SftCard
                                                                nftInfo={item}
                                                                amount={item.amount}
                                                                shouldShowCheckbox={true}
                                                            />
                                                        )
                                                    }
                                                </Flex>
                                            ))
                                        }
                                    </Grid>
                                )
                            }
                        </Flex>
                    </Flex>
                )
            }
        </Flex >
    )
}

export default MyTokens
