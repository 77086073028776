import React, { Dispatch, SetStateAction } from 'react';
import {
    Button,
    Checkbox,
    Flex,
    Image,
    Tooltip,
} from '@chakra-ui/react';
import { firstColor, newChainAttrs, secondColor, thirdColor } from '../../utils/config';
import { TokenSaleStatus, NftType, TokenType, CheckType } from '../../utils/types';
import { useNavigate } from 'react-router-dom';
import { useChainContext } from '../../utils/Context';
import Favorite from '../Favorite';
import TokenImage from '../TokenImage';

interface IProps {
    nftInfo: NftType;
    shouldShowCheckbox?: boolean;
}

const NftCard = (props: IProps) => {
    const { nftInfo, shouldShowCheckbox } = props;
    const {
        _id,
        chainId,
        tokenId,
        contractAddress,
        tokenType,
        name,
        owner,
        artist,
        tokenSaleStatus,
        imageUrl,
        price,
        lastSalePrice,
        favoriteRate
    } = nftInfo;

    const navigate = useNavigate();
    const { user, selectedTokenObjectIds, setSelectedTokenObjectIds } = useChainContext();

    const handleSelectedTokenObjectIds = async (tokenObjectId: string) => {
        let updatedSelectedTokenObjectIds: string[] = [];
        if (selectedTokenObjectIds.includes(tokenObjectId)) {
            updatedSelectedTokenObjectIds = selectedTokenObjectIds.filter(item => item != tokenObjectId);
        } else {
            updatedSelectedTokenObjectIds = [...selectedTokenObjectIds, tokenObjectId];
        }
        setSelectedTokenObjectIds(updatedSelectedTokenObjectIds);
    }


    return (
        <Flex
            className='nft-card'
            p={['10px']}
            position={'relative'}
            direction={['column']}
            background={thirdColor}
            width={['320px']}
            zIndex={0}
            border={user?.publicKey?.toLowerCase() == owner?.toLowerCase() ? ['1px solid white'] : 'unset'}
        >
            {
                shouldShowCheckbox && (
                    <Flex
                        position={'absolute'}
                        top={'10px'}
                        right={['10px']}
                    >
                        <Checkbox
                            isChecked={selectedTokenObjectIds.includes(_id)}
                            onChange={() => handleSelectedTokenObjectIds(_id)}
                        />
                    </Flex>
                )
            }

            <TokenImage
                widthString={['300px']}
                imageUrl={imageUrl}
                isCsdogeToken={artist != null}
                shouldShowCheckbox={shouldShowCheckbox}
                onClick={() => shouldShowCheckbox && handleSelectedTokenObjectIds(_id)}
            />
            <Flex
                className='nft-abstract'
                direction={'column'}
                px={['5px']}
                pt={['5px']}
            >
                <Flex
                    justifyContent={'center'}
                    fontFamily={'Poppins'}
                    className={'title'}
                    color={'white'}
                >
                    Csdoge Marketplace
                </Flex>
                <Flex
                    pt={['20px']}
                    direction={['column']}
                >
                    <Flex
                        direction={['column']}
                        color={'white'}
                    >
                        <Flex>
                            <Flex
                                fontFamily={'Poppins'}
                                gap={['5px']}
                                width={['50%']}
                            >
                                <Flex
                                >
                                    Chain:
                                </Flex>
                                <Image
                                    width={'20px'}
                                    src={newChainAttrs[chainId].logo}
                                />
                            </Flex>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                TokenId: #{tokenId}
                            </Flex>
                        </Flex>

                        <Flex
                            fontFamily={'Poppins'}
                        >
                            Type: {tokenType == TokenType.NFT ? 'NFT' : 'SFT'}
                        </Flex>
                        <Tooltip hasArrow label={name} placement='top' color={'white'} bg={firstColor} p={['5px 10px']} fontSize={['14px']}>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                {name ? `Name: ${name.length > 15 ? name.slice(0, 8) + '...' + name.slice(name.length - 6) : name}` : ''}
                            </Flex>
                        </Tooltip>
                        <Tooltip hasArrow label={name} placement='top' color={'white'} bg={firstColor} p={['5px 10px']} fontSize={['14px']}>
                            <Flex
                                fontFamily={'Poppins'}
                            >
                                {artist ? `Artist: ${artist.length > 15 ? artist.slice(0, 8) + '...' + artist.slice(artist.length - 6) : artist}` : ''}
                            </Flex>
                        </Tooltip>
                        <Flex
                            fontFamily={'Poppins'}
                            height={['24px']}
                        >
                            {
                                price != undefined && (tokenSaleStatus == TokenSaleStatus.NotInSale ? '' : `Price : ${price} ${newChainAttrs[chainId].nativeToken}`)
                            }
                        </Flex>
                        <Flex
                            fontFamily={'Poppins'}
                            height={['24px']}
                        >
                            {
                                lastSalePrice != undefined && (lastSalePrice == 0 ? '' : `Last sale : ${lastSalePrice} ${newChainAttrs[chainId].nativeToken}`)
                            }
                        </Flex>
                    </Flex>
                    <Flex
                        justifyContent={['center']}
                    >
                        <Button
                            zIndex={100}
                            bg={tokenSaleStatus != TokenSaleStatus.NotInSale ? firstColor : secondColor}
                            color={tokenSaleStatus != TokenSaleStatus.NotInSale ? 'white' : firstColor}
                            fontWeight={'900'}
                            _hover={{
                                background: secondColor,
                            }}
                            _selected={{
                                background: secondColor,
                            }}
                            px={['30px']}
                            py={['5px']}
                            onClick={() => navigate(
                                owner.toLowerCase() == user?.publicKey?.toLowerCase()
                                    ?
                                    `/nft/${contractAddress}/${chainId}/${tokenId}/sell`
                                    :
                                    `/nft/${contractAddress}/${chainId}/${tokenId}/detail`
                            )}
                        >
                            View
                        </Button>
                    </Flex>
                </Flex>

                <Flex
                    justifyContent={['center']}
                    mt={['5px']}
                    height={['30px']}
                    alignItems={['center']}
                >
                    <Favorite
                        favoriteRate={favoriteRate ?? 0}
                        tokenObjectId={''}
                        setFavoriteRate={() => { }}
                        isRateChangable={false}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}

export default NftCard;
