import {
  Box,
} from '@chakra-ui/react'

import BidTable from '../../components/MyBid/BidTable';

const MyBid = () => {

  return (
    <Box
      className='my-bid-page-wrapper-component'
      width={'100%'}
      justifyContent={'center'}
      p={['40px']}
    >
      <BidTable />
    </Box >
  )
}

export default MyBid
