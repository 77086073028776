import {
    Button,
    Flex,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from '@chakra-ui/react';
import { SlMagnifier, } from 'react-icons/sl';
import { IoMdArrowDropdown } from 'react-icons/io';
import { firstColor, secondColor, textStatuses } from '../../utils/config';
import { TextStatus } from '../../utils/types';
import { useEffect, useState } from 'react';


type SearchComponentProps = {
    searchText: string,
    setSearchText: (searchText: string) => void,
    textStatus: TextStatus,
    setTextStatus: (textStatus: TextStatus) => void,
    getResultTokens: () => void,
}

const SearchComponent = ({
    searchText,
    setSearchText,
    textStatus,
    setTextStatus,
    getResultTokens,
}: SearchComponentProps) => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
        setWidth(window.innerWidth);
    }, [])

    return (
        <Flex
            className='find-by-component'
            border={[`1px solid ${secondColor}`]}
            borderRadius={['10px']}
            width={['100%']}
            maxWidth={['1200px']}
        >
            <Flex
                alignItems={['center']}
                px={['5px', '10px']}
            >
                <SlMagnifier color={'white'} />
            </Flex>
            <Input
                className='search-text'
                placeholder='Search by name, category, artist, description'
                border={['none']}
                type={'string'}
                px={['0']}
                _focusVisible={{
                    border: 'none',
                }}
                width={['100%']}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyUp={e => { if (e.code == 'Enter') getResultTokens() }}
                color={'white'}
            />

            <Flex
                borderLeft={[`1px solid ${secondColor}`]}
                borderRadius={['0']}
                fontSize={[width < 1400 ? '14px' : '16px']}
            >
                <Menu
                >
                    <MenuButton
                        as={Button}
                        px='0'
                        backgroundColor='transparent'
                        _hover={{
                            backgroundColor: 'transparent',
                        }}
                        _focus={{
                            backgroundColor: 'transparent',
                        }}
                        _active={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <Flex>
                            <Flex
                                direction='row'
                                alignSelf='center'
                                alignItems='center'
                                color={['white']}
                                px={['5px', '10px']}
                                width={['110px']}
                            >
                                {textStatuses[textStatus].label}
                            </Flex>
                            <Flex>
                                <Flex
                                    direction='column'
                                    justifyContent='center'
                                    px={['5px', '10px']}
                                >
                                    <IoMdArrowDropdown
                                        color='white'
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </MenuButton>
                    <MenuList
                        backgroundColor={firstColor}
                        minWidth='min-content'
                        width='170px'
                        zIndex={'3'}
                    >
                        {
                            textStatuses.map((item, index) => (
                                <MenuItem
                                    _hover={{
                                        backgroundColor: secondColor,
                                    }}
                                    _focus={{
                                        backgroundColor: secondColor,
                                    }}
                                    onClick={() => {
                                        setTextStatus(item.value);
                                    }}
                                    key={index}
                                    fontFamily='Poppins'
                                    fontStyle='normal'
                                    fontWeight='700'
                                    fontSize={width < 1400 ? '14px' : '16px'}
                                    lineHeight='16px'
                                    paddingLeft='15px'
                                    background={firstColor}
                                    color='white'
                                >
                                    <Text
                                    >
                                        {item.label}
                                    </Text>
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    )
}

export default SearchComponent
