import {
    Button,
    Flex,
    Grid,
    Image,
    Input,
    useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, } from 'react';
import { GroupInfo, TokenType, UserType } from '../../utils/types';
import axios from 'axios';
import { useChainContext } from '../../utils/Context';
import SftCard from '../../components/Card/SftCard';
import NftCard from '../../components/Card/NftCard';
import { secondColor, thirdColor } from '../../utils/config';
import RenameGroupModal from '../../components/modal/RenameGroupModal';
import { toast } from 'react-toastify';

const Group = () => {
    const { groupIndex, } = useParams();
    const navigate = useNavigate();
    const [groupInfo, setGroupInfo] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [width, setWidth] = useState(0);
    const {
        user,
        sockets,
        selectedTokenObjectIds,
    } = useChainContext();
    const {
        isOpen: isOpenRenameGroupModal,
        onOpen: onOpenRenameGroupModal,
        onClose: onCloseRenameGroupModal,
    } = useDisclosure();

    const getGroupInfo = async (user: UserType) => {
        (async () => {
            setIsLoading(true);
            try {
                let groupInfo: any = (await axios.get(`/group/${user._id}/${groupIndex}`)).data;
                console.log({ groupInfo });

                setGroupInfo(groupInfo);
            } catch (e) {
                console.log("error happened in getting group info: ", e);
            }
            setIsLoading(false);
        })()
    }

    useEffect(() => {
        (async () => {
            if (user) {
                await getGroupInfo(user);
            }
        })()
    }, [user])

    const handleRemoveGroup = async () => {
        try {
            await axios.post('/group/removeGroup', {
                groupIndex: groupInfo!.groupIndex,
            });
            toast.success('Successfully removed group');
            navigate('/my-nfts');
        } catch (e) {
            console.log("error happened in removing group: ", e);
        }
    }

    const handleRemoveItemsInGroup = async () => {
        console.log({
            tokenObjectIds: selectedTokenObjectIds,
            groupIndex: groupInfo!.groupIndex,
        })

        try {
            await axios.post('/group/removeFromGroup', {
                tokenObjectIds: selectedTokenObjectIds,
                groupIndex: groupInfo!.groupIndex,
            });
            console.log(selectedTokenObjectIds);
            const newTokens = groupInfo.tokens.filter((item: any) => selectedTokenObjectIds.indexOf(item._id) < 0);
            console.log(newTokens);
            setGroupInfo((prev: any) => ({...prev , tokens: newTokens}));
            toast.success('Successfully removed items in group');
        } catch (e) {
            console.log("error happened in removing items in group: ", e);
        }
    }

    useEffect(() => {
        if (!sockets.groups) return;

        sockets.groups.on("renameGroupNameNotify", async (renameGroupNameNotify: GroupInfo) => {
            if (user && renameGroupNameNotify.user.toString() == user._id.toString() && renameGroupNameNotify.groupIndex == Number(groupIndex ?? 5)) {
                await getGroupInfo(user);
            }
        });

        sockets.groups.on("removeFromGroupSuccessNotify", async (removeFromGroupSuccessNotify: GroupInfo) => {
            if (user && removeFromGroupSuccessNotify.user.toString() == user._id.toString() && removeFromGroupSuccessNotify.groupIndex == Number(groupIndex ?? 5)) {
                await getGroupInfo(user);
            }
        });
    }, [sockets, user]);

    return (
        <>
            {
                groupInfo && (
                    <Flex
                        justifyContent={['flex-start']}
                        className='group-page'
                        width={['100%']}
                        mt={['70px']}
                        direction={['column']}
                    >
                        <Flex
                            className='group-name'
                            fontFamily={'Poppins'}
                            fontWeight={'700'}
                            fontSize={'23px'}
                            lineHeight={'30px'}
                            color={secondColor}
                            mb={['10px']}
                            pl={['50px']}
                        >
                            {groupInfo?.groupName}
                        </Flex>
                        <Flex
                            direction={['column', 'column', 'row']}
                            justifyContent={['right']}
                            alignItems={['center']}
                            gap={['20px']}
                            p={['20px']}
                        >
                            <Button
                                border={`1px solid ${secondColor}`}
                                borderRadius={'45px'}
                                px={'28px'}
                                py={'7px'}
                                my={['5px', '15px']}
                                onClick={handleRemoveGroup}
                                fontFamily={'Poppins'}
                                fontSize={'16px'}
                                lineHeight={'19px'}
                                cursor={'pointer'}
                                width={['200px']}
                                height={['45px']}
                                background={thirdColor}
                                color={'white'}
                                fontWeight={'900'}
                                _hover={{
                                    background: secondColor,
                                }}
                                _selected={{
                                    background: secondColor,
                                }}
                            >
                                Remove Group
                            </Button>

                            <Button
                                border={`1px solid ${secondColor}`}
                                borderRadius={'45px'}
                                px={'28px'}
                                py={'7px'}
                                my={['5px', '15px']}
                                onClick={onOpenRenameGroupModal}
                                fontFamily={'Poppins'}
                                fontSize={'16px'}
                                lineHeight={'19px'}
                                cursor={'pointer'}
                                width={['200px']}
                                height={['45px']}
                                background={thirdColor}
                                color={'white'}
                                fontWeight={'900'}
                                _hover={{
                                    background: secondColor,
                                }}
                                _selected={{
                                    background: secondColor,
                                }}
                            >
                                Rename Group
                            </Button>

                            <Button
                                border={`1px solid ${secondColor}`}
                                borderRadius={'45px'}
                                px={'28px'}
                                py={'7px'}
                                my={['5px', '15px']}
                                onClick={handleRemoveItemsInGroup}
                                fontFamily={'Poppins'}
                                fontSize={'16px'}
                                lineHeight={'19px'}
                                cursor={'pointer'}
                                width={['240px']}
                                height={['45px']}
                                background={thirdColor}
                                color={'white'}
                                fontWeight={'900'}
                                _hover={{
                                    background: secondColor,
                                }}
                                _selected={{
                                    background: secondColor,
                                }}
                            >
                                Remove items in Group
                            </Button>
                        </Flex>
                        <Flex
                            direction={'column'}
                        >
                            {
                                isLoading && (
                                    <Flex
                                        className="loading-container"
                                        justifyContent={'center'}
                                        mt={'50px'}
                                        alignItems={'center'}
                                        width={['100vw']}
                                    >
                                        <section>
                                            <Flex className="loader loader-1">
                                                <Flex className="loader-outter" />
                                                <Flex className="loader-inner" />
                                            </Flex>
                                        </section>
                                    </Flex>
                                )
                            }
                            {
                                !isLoading && (
                                    <Grid
                                        templateColumns={[
                                            'repeat(1, 1fr)',
                                            'repeat(1, 1fr)',
                                            'repeat(2, 1fr)',
                                            'repeat(2, 1fr)',
                                            'repeat(3, 1fr)',
                                            width < 1992 ? 'repeat(4, 1fr)' : 'repeat(5, 1fr)',
                                        ]}
                                        gap={['20px', '20px', '10px', '41px']}
                                        mt={['10px', '20px', '30px', '35px']}
                                    >
                                        {
                                            groupInfo && groupInfo.tokens && groupInfo.tokens.map((item: any, index: number) => (
                                                <Flex
                                                    key={index}
                                                    justifyContent={'center'}
                                                >
                                                    {
                                                        item.tokenType == TokenType.NFT ? (
                                                            <NftCard
                                                                nftInfo={item}
                                                                shouldShowCheckbox={true}
                                                            />
                                                        ) : (
                                                            <SftCard
                                                                nftInfo={item}
                                                                shouldShowCheckbox={true}
                                                            />
                                                        )
                                                    }
                                                </Flex>
                                            ))
                                        }
                                    </Grid>
                                )
                            }


                        </Flex>
                        <RenameGroupModal
                            isOpenRenameGroupModal={isOpenRenameGroupModal}
                            onCloseRenameGroupModal={onCloseRenameGroupModal}
                            groupIndex={groupInfo?.groupIndex ?? 0}
                        />
                    </Flex >
                )
            }
        </>
    )
}

export default Group;
