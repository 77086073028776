import {
  Button,
  Flex,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import axios from 'axios';
import { useState, useEffect, } from 'react';
import { toast } from 'react-toastify';
import { CONTRACT_OWNER, secondColor, sftSellContractAddresses, nftSellContractAddresses } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { useAccount, useNetwork } from 'wagmi';
import { writeContract } from '@wagmi/core';
import { nftSellContractAbi } from '../../utils/assets';

const MyProfile = () => {
  const {
    user,
  } = useChainContext();
  const [name, setName] = useState<string>();
  const [publicKey, setPublicKey] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [country, setCountry] = useState<string>();
  const [address, setAddress] = useState<string>();
  const { address: userAddr } = useAccount();
  const { chain } = useNetwork();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const {
    isOpen: isOpenListingSuccessModal,
    onOpen: onOpenListingSuccessModal,
    onClose: onCloseListingSuccessModal,
  } = useDisclosure();

  useEffect(() => {
    if (user) {
      setName(user.username != `name-${user.publicKey}` ? user.username : '');
      setPublicKey(user.publicKey);
      setEmail(user.email != `${user.publicKey}@gmail.com` ? user.email : '');
      setPhone(user.phone != `1-${user.publicKey}` ? user.phone : '');
      setCountry(user.country);
      setAddress(user.address);
    }
  }, [user])

  const updateProfile = async () => {
    if (user) {
      const profileData = {
        username: name,
        publicKey: publicKey,
        email,
        phone,
        country,
        address,
        signInType: 'sign-in',
      };

      const updateResult = (await axios.post(`/user/updateProfile`, profileData)).data;
      console.log({ updateResult });

      if (updateResult.isSuccess) {
        toast.success('Your profile is updated successfully');
      } else {
        toast.error('Failed');
      }
    } else {
      toast.error('Please login at first');
    }
  }

  const withdraw = async () => {
    if (!chain) return;
    try {
      const tx1 = await writeContract({
        address: (nftSellContractAddresses as any)[chain.id],
        abi: nftSellContractAbi,
        functionName: 'withdraw',
        args: [],
      });
      console.log("transaction hash: ", tx1.hash);
      toast.success('Withdraw Success');


      const tx2 = await writeContract({
        address: (sftSellContractAddresses as any)[chain.id],
        abi: nftSellContractAbi,
        functionName: 'withdraw',
        args: [],
      });
      console.log("transaction hash: ", tx2.hash);
      toast.success('Withdraw Success');


    } catch (error) {
      toast.error('Withdraw failed');
      setIsProcessing(false);
    }
  }


  return (
    <>
      <Flex
        className='my-profile-page-wrapper-component'
        width={'100%'}
        justifyContent={'center'}
      >
        <Flex
          className='my-profile-page-component'
          direction={'column'}
          py={['50px', '50px', '100px']}
          gap={['50px']}
        >
          <Flex
            className='my-profile'
            direction={['column', 'column', 'column', 'row']}
            // width={['300px', '350px', '300px', '350px']}
            justifyContent={'center'}
            mt={['10px']}
            mb={['20px']}
            gap={['40px']}
          >
            <Flex
              direction={'column'}
              gap={['40px']}
              width={['300px', '350px', '300px', '350px']}
            >
              <Flex
                className="name"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  Name
                </Flex>

                <Input
                  // value={name}
                  defaultValue={name}
                  variant="flushed"
                  placeholder='Type your name'
                  borderBottom={`2px solid ${secondColor}`}
                  onChange={(e) => setName(e.target.value)}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>

              <Flex
                className="publicKey"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  PublicKey
                </Flex>

                <Input
                  // value={publicKey}
                  defaultValue={publicKey}
                  variant="flushed"
                  placeholder='Type public key'
                  borderBottom={`2px solid ${secondColor}`}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>

              <Flex
                className="email"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  Email
                </Flex>

                <Input
                  // value={email}
                  defaultValue={email}
                  variant="flushed"
                  placeholder='Type the Email'
                  borderBottom={`2px solid ${secondColor}`}
                  onChange={(e) => setEmail(e.target.value)}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>

            </Flex>
            <Flex
              direction={'column'}
              gap={['40px']}
              width={['300px', '350px', '300px', '350px']}
            >
              <Flex
                className="phone"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  Phone
                </Flex>

                <Input
                  // value={phone}
                  defaultValue={phone}
                  variant="flushed"
                  placeholder='Type the Email'
                  borderBottom={`2px solid ${secondColor}`}
                  onChange={(e) => setPhone(e.target.value)}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>

              <Flex
                className="country"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  Country
                </Flex>

                <Input
                  // value={country}
                  defaultValue={country}
                  variant="flushed"
                  placeholder='Type the country'
                  borderBottom={`2px solid ${secondColor}`}
                  onChange={(e) => setCountry(e.target.value)}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>

              <Flex
                className="address"
                direction={'column'}
                mt={['20px', '20px', 'unset']}
                gap={['10px']}
              >
                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'22px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                >
                  Address
                </Flex>

                <Input
                  // value={address}
                  defaultValue={address}
                  variant="flushed"
                  placeholder='Type the address'
                  borderBottom={`2px solid ${secondColor}`}
                  onChange={(e) => setAddress(e.target.value)}
                  color={'white'}
                  fontFamily={'Poppins'}
                  _focusVisible={{
                    boxShadow: 'none',
                  }}
                />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            justifyContent={'center'}
          >
            <Button
              onClick={updateProfile}
              variant={'solid'}
              width={['80px', '150px', '150x', '180px']}
              height={['35px', '35px', '35px', '41px']}
              borderRadius={['35px', '35px', '35px', '35px']}
              border={`1px solid ${secondColor}`}
              backgroundColor={'#2E2E49'}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              <Text
                fontSize={['14px', '14px', '16px', '18px']}
                fontFamily={'Poppins'}
                fontWeight="700px"
                lineHeight={'25px'}
              >
                Update
              </Text>
            </Button>
          </Flex>
          {
            userAddr?.toLowerCase() == CONTRACT_OWNER.toLowerCase() ?
              <Flex
                justifyContent={'center'}
              >
                <Button
                  onClick={withdraw}
                  variant={'solid'}
                  width={['80px', '150px', '150x', '180px']}
                  height={['35px', '35px', '35px', '41px']}
                  borderRadius={['35px', '35px', '35px', '35px']}
                  border={`1px solid ${secondColor}`}
                  backgroundColor={'#2E2E49'}
                  color={'white'}
                  fontWeight={'900'}
                  _hover={{
                    background: secondColor,
                  }}
                  _selected={{
                    background: secondColor,
                  }}
                >
                  <Text
                    fontSize={['14px', '14px', '16px', '18px']}
                    fontFamily={'Poppins'}
                    fontWeight="700px"
                    lineHeight={'25px'}
                  >
                    Withdraw
                  </Text>
                </Button>
              </Flex> : ''
          }
        </Flex>
      </Flex >
      {/* <AnnounceModal
        isOpenAnnounceModal={isProcessing}
        onCloseAnnounceModal={() => setIsProcessing(false)}
        announceText={'Your transaction is currently processing'}
        announceGif={true}
        announceModalButtonText={'Close'}
      />
      <AnnounceModal
        isOpenAnnounceModal={isOpenListingSuccessModal}
        onCloseAnnounceModal={onCloseListingSuccessModal}
        // announceText={`${isFixedSale ? 'Fixed Sale' : 'Auction'} has been successfully listed`}
        announceText={'Your transaction is success'}
        announceLogo={checkIconInGreenBg}
        announceModalButtonText={'Close'}
      /> */}
    </>
  )
}

export default MyProfile
