import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAccount, useNetwork } from 'wagmi';
import { writeContract, switchNetwork } from '@wagmi/core'
import TokenImage from '../TokenImage';
import AnnounceModal from '../modal/AnnounceModal';
import { delay } from '../../utils/formatters';
import { newChainAttrs, nftSellContractAddresses } from '../../utils/config';
import { checkIconInGreenBg, exclamationIconInRedBg, nftSellContractAbi } from '../../utils/assets';
import axios from 'axios';

type TokenImageWrapperProps = {
    imageUrl: string,
    name: string,
    nftAddress: string,
    tokenId: number,
    chainId: number,
    isDesktop: boolean,
    isCsdogeToken: boolean,
    isOnSale: boolean,
}

const TokenImageWrapper = ({
    imageUrl,
    name,
    nftAddress,
    tokenId,
    chainId,
    isDesktop,
    isCsdogeToken,
    isOnSale,
}: TokenImageWrapperProps) => {
    const navigate = useNavigate();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenListingSuccessModal,
        onOpen: onOpenListingSuccessModal,
        onClose: onCloseListingSuccessModal,
    } = useDisclosure();

    const burnNft = async () => {
        if (!address) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (chainId != chain?.id) {
            await switchNetwork({ chainId });
        }

        setIsProcessing(true);

        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[chainId],
                abi: nftSellContractAbi,
                functionName: 'burnNft',
                args: [
                    nftAddress,
                    tokenId,
                ],
            });
            console.log("transaction hash: ", tx.hash);

            await axios.post('/fixedSale/burnNft', {
                nftAddress: nftAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
            });

            await delay(20000);

            onOpenListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            console.log("burn nft error: ", e);
            toast.error('Your operation failed: ', e);

            setIsProcessing(false);
        }
    }

    return (
        <Flex
            className="token-image-wrapper"
            display={isDesktop ? ['none', 'none', 'none', 'flex'] : ['flex', 'flex', 'flex', 'none']}
            gap={['40px']}
            direction={isDesktop ? 'column' : 'row'}
            position={'relative'}
        >
            <TokenImage
                widthString={isDesktop ? ['300px', '350px', '350px', '400px'] : ['50px']}
                imageUrl={imageUrl}
                hasBorder
                isCsdogeToken={isCsdogeToken}
            />

            <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={isDesktop ? '22px' : '30px'}
                lineHeight={'30px'}
                color={'white'}
                textTransform={'capitalize'}
                justifyContent={'center'}
                alignItems={'center'}
                position={'relative'}
            >
                <Flex
                    position={'absolute'}
                    top={['-27px', '-27px', '-27px', '-27px']}
                    left={0}
                    display={'flex'}
                    direction={'row'}
                    alignItems={'center'}
                >
                    <Image
                        width={'25px'}
                        src={newChainAttrs[chainId].logo}
                    />
                </Flex>

                {
                    !isOnSale && (
                        <Flex
                            position={'absolute'}
                            top={['-27px', '-27px', '-27px', '-27px']}
                            right={0}
                            display={'flex'}
                            direction={'row'}
                            alignItems={'center'}
                            gap={'5px'}
                        >
                            <Button
                                border={`1px solid #c91d12`}
                                borderRadius={'45px'}
                                px={'10px'}
                                py={'5px'}
                                size={'xs'}
                                fontFamily={'Poppins'}
                                fontSize={'14px'}
                                lineHeight={'19px'}
                                cursor={'pointer'}
                                background={'none'}
                                color={'#c91d12'}
                                fontWeight={'900'}
                                opacity={0.8}
                                _hover={{
                                    opacity: 1,
                                }}
                                _focus={{
                                    background: 'none',
                                }}
                                className='hover:scale-[1.05]'
                                onClick={() => setShowWarningModal(true)}
                            >
                                <Text fontFamily={'Poppins'}>Burn</Text>
                                <AiOutlineDelete
                                    size={16}
                                />
                            </Button>
                        </Flex>
                    )
                }

                {name}
            </Flex>

            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenListingSuccessModal}
                onCloseAnnounceModal={() => { navigate('/my-nfts') }}
                announceText={'Your transaction is success'}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />

            <AnnounceModal
                isOpenAnnounceModal={showWarningModal}
                onCloseAnnounceModal={() => setShowWarningModal(false)}
                announceText={`Are you sure you want to destroy this NFT ?`}
                announceLogo={exclamationIconInRedBg}
                announceModalButtonText={'Yes'}
                announceModalNoButtonShow={true}
                announceModalButtonAction={() => {
                    if (!address) {
                        toast.error('Please login your wallet at first');
                    } else {
                        burnNft();
                        setShowWarningModal(false);
                    }
                }}
            />
        </Flex>
    )
}

export default TokenImageWrapper;