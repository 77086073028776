import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { HttpStatusCode } from 'axios';
import {
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { useAccount, useNetwork } from 'wagmi';
import { disconnect, writeContract, switchNetwork } from '@wagmi/core'
import AnnounceModal from '../modal/AnnounceModal';
import {
    secondColor,
    nftSellContractAddresses,
    thirdColor,
} from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { checkIconInGreenBg, nftSellContractAbi } from '../../utils/assets';
import { getSignature } from '../../utils/interact';
import { delay } from '../../utils/formatters';

type CancelListingProps = {
    tokenId: number;
    nftContractAddress: string | undefined;
    isFixedSale: boolean;
    nftObjectId: string | null;
    shouldRender: boolean;
    setShouldRender: (shouldRender: boolean) => void;
}

const CancelListing = ({
    tokenId,
    nftContractAddress,
    isFixedSale,
    nftObjectId,
    shouldRender,
    setShouldRender,
}: CancelListingProps) => {
    const { chainId } = useParams();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { user } = useChainContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenCancelListingSuccessModal,
        onOpen: onOpenCancelListingSuccessModal,
        onClose: onCloseCancelListingSuccessModal,
    } = useDisclosure();

    const cancelFixedSale = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: (nftSellContractAddresses as any)[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'cancelFixedSale',
                args: [
                    nftContractAddress,
                    tokenId,
                    signature,
                ],
            });

            console.log("transaction hash: ", tx.hash);

            await axios.post('/fixedSale/cancelFixedSale', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
            });

            await delay(20000);

            onOpenCancelListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("nft fixed sale cancel error: ", e);
                toast.error('Your operation failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const cancelNftAuction = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: (nftSellContractAddresses as any)[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'cancelNftAuction',
                args: [
                    nftContractAddress,
                    tokenId,
                    signature,
                ],
            });

            console.log("transaction hash: ", tx.hash);

            await axios.post('/auction/cancelAuction', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
            });

            await delay(20000);

            onOpenCancelListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("nft auction cancel error: ", e);
                toast.error('Your operation failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const cancelListing = async () => {
        if (!chain || !user) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (parseInt(chainId ?? '') != chain?.id) {
            await switchNetwork({ chainId: Number(chainId) });
        }
        setIsProcessing(true);
        const signatureResult = await getSignature(
            user!,
            Number(chainId),
            nftContractAddress!,
            tokenId,
            1, // for compatibility with sign contract
        )
        if (!signatureResult?.isSuccess) {
            toast.error('Signature Error');
            setIsProcessing(false);
            return;
        }

        if (isFixedSale) {
            await cancelFixedSale(signatureResult.signature);
        } else {
            await cancelNftAuction(signatureResult.signature);
        }
    }

    return (
        <>
            <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'7px'}
                mt={'30px'}
                onClick={() => cancelListing()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['240px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                fontWeight={'900'}
                _hover={{
                    background: secondColor,
                }}
                _selected={{
                    background: secondColor,
                }}
            >
                Cancel listing
            </Button>
            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenCancelListingSuccessModal}
                onCloseAnnounceModal={() => {
                    setShouldRender(!shouldRender);
                    onCloseCancelListingSuccessModal();
                }}
                announceText={`${isFixedSale ? 'Fixed Sale' : 'Auction'} has been successfully canceld`}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />
        </>
    )
}

export default CancelListing;
