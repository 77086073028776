import { useState, useEffect } from 'react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  Flex,
  Image,
  Link,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from '@chakra-ui/react'
import { useAccount, useNetwork, useSwitchNetwork, } from 'wagmi';
import { signMessage, disconnect } from '@wagmi/core';
import NavLink from './Navlink'
import Account from '../Account'
import {
  bscChainId,
  bscTestnetChainId,
  chainAttrs,
  firstColor,
  linkArray,
  messageToSign,
  mumbaiChainId,
  newChainAttrs,
  polygonChainId,
  secondColor,
  thirdColor,
} from '../../utils/config';
import { ChainAttrItemType, UserType } from '../../utils/types';
import AnnounceModal from '../modal/AnnounceModal';
import { exclamationIconInRedBg, logo } from '../../utils/assets';
import axios, { HttpStatusCode } from 'axios';
import { useChainContext, } from '../../utils/Context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const isMainnet = process.env.REACT_APP_IS_MAINNET == 'true';

const Header = () => {
  const navigate = useNavigate();
  const { address, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { sockets, user, setUser } = useChainContext();

  const [chainAttrItem, setChainAttrItem] = useState<ChainAttrItemType>(newChainAttrs[isMainnet ? bscChainId : bscTestnetChainId]);
  const [currentMainnetOrTestnetAttrs,] = useState(isMainnet ? chainAttrs.mainnet : chainAttrs.testnet);
  const [chainIndex, setChainIndex] = useState<number>(4);
  const [width, setWidth] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenChainSwitchModal,
    onOpen: onOpenChainSwitchModal,
    onClose: onCloseChainSwitchModal,
  } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen())

  const limitSizeBetweenDesktopandMobile = 1170;
  const desktopDisplay = ['none', 'none', 'none', width <= limitSizeBetweenDesktopandMobile ? 'none' : 'flex'];
  const mobileDisplay = ['flex', 'flex', 'flex', width <= limitSizeBetweenDesktopandMobile ? 'flex' : 'none'];

  // (window as any).ethereum?.on('networkChanged', async () => {
  //   window.location.reload();
  // })

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const chainId = (chain?.id != undefined && Object.keys(newChainAttrs).includes(chain?.id?.toString()))
      ? chain.id
      :
      isMainnet ? bscChainId : bscTestnetChainId;
    setChainAttrItem(newChainAttrs[chainId]);
  }, [chain]);

  useEffect(() => {
    if (isDisconnected) {
      (async () => {
        await axios.get("/auth/logout");
        setUser(null);
      })();
    }
  }, [isDisconnected]);

  // useEffect(() => {
  //   if (!(sockets && sockets.userSocket)) return;

  //   if (user?._id) {
  //     console.log('subscribeToProfile');
  //     sockets.userSocket.emit("subscribeToProfile", user._id);
  //   }
  // }, [sockets?.userSocket, user?._id]);

  useEffect(() => {
    const signAndAuth = async (walletAddress: string) => {
      try {
        if (
          !walletAddress
        )
          return;

        let { authenticated, user } = (await axios.get("/auth/state")).data;

        if (authenticated) {
          if (user.publicKey.toLowerCase() === walletAddress.toLowerCase()) {
            setUser(user);
            return;
          }
          console.log("PUBLIC KEYS ARE NOT THE SAME!");
          await axios.get("/auth/logout");
          setUser(null);
        }

        const signature = await signMessage({
          message: messageToSign,
        });

        user = (
          await axios.post(
            "/auth/login",
            {
              publicKey: walletAddress.toLowerCase(),
              signature: signature,
            },
            { withCredentials: true }
          )
        ).data;

        if (user?.publicKey) {
          setUser(user);
        }
      } catch (e: any) {
        console.log('e', e);

        await disconnect();
        setUser(null);

        if (e?.response?.status == 401) {
          toast.error('Sorry, please refresh website and sign again');
        } else {
          toast.error(e?.response?.data?.message?.toString());
        }
      }
    };

    if (address) {
      signAndAuth(address);
    }
  }, [address, chain])

  const ChainIcon = () => (
    <Flex
      alignItems='center'
      className='chain-logo-menu-wrapper header-chain-icon'
    >
      <Menu
      >
        <MenuButton
          as={Button}
          px='0'
          backgroundColor='transparent'
          _hover={{
            backgroundColor: 'transparent',
          }}
          _focus={{
            backgroundColor: 'transparent',
          }}
          _active={{
            backgroundColor: 'transparent',
          }}
        >
          <Flex
            direction='row'
            alignSelf='center'
            alignItems='center'
          >
            <Image
              width={'26px'}
              height={'26px'}
              src={chainAttrItem?.logo}
              alt={chainAttrItem?.alt}
              mr='3'
            />
          </Flex>
        </MenuButton>
        <MenuList
          backgroundColor={firstColor}
          minWidth='min-content'
          width='170px'
          zIndex={'3'}
        >
          {
            currentMainnetOrTestnetAttrs.map((item, index) => (
              <MenuItem
                _hover={{
                  backgroundColor: secondColor,
                }}
                _focus={{
                  backgroundColor: secondColor,
                }}
                onClick={() => {
                  if (currentMainnetOrTestnetAttrs[index].chainId != chain?.id) {
                    setChainIndex(index);
                    onOpenChainSwitchModal();
                  }
                }}
                key={index}
                fontFamily='Poppins'
                fontStyle='normal'
                fontWeight='700'
                fontSize='16px'
                lineHeight='16px'
                paddingLeft='15px'
                background={firstColor}
              >
                <Image
                  width={'26px'}
                  height={'26px'}
                  src={item.logo}
                  alt={item.alt}
                  mr='4'
                />
                <Text
                  textTransform='capitalize'
                  color={'white'}
                >
                  {item.name}
                </Text>
              </MenuItem>
            ))
          }
        </MenuList>
      </Menu>
      <AnnounceModal
        isOpenAnnounceModal={isOpenChainSwitchModal}
        onCloseAnnounceModal={() => onCloseChainSwitchModal()}
        announceText={`Are you going to switch chain to ${currentMainnetOrTestnetAttrs[chainIndex]?.name} ?`}
        announceLogo={exclamationIconInRedBg}
        announceModalButtonText={'Switch Chain'}
        announceModalButtonAction={() => {
          if (address) {
            switchNetwork?.(currentMainnetOrTestnetAttrs[chainIndex].chainId);
          } else {
            toast.error('Please login your wallet at first');
          }
          onCloseChainSwitchModal();
        }}
      />
    </Flex >
  )

  return (
    <Flex
      direction='column'
    >
      <Flex
        bg={firstColor}
        py='3'
        justifyContent='space-between'
        px={['15px', '15px', '15px', '15px', '30px']}
      >
        {/* logo icon */}
        <Flex
          justifyContent={'center'}
          alignItems="center"
          zIndex='999'
          className='logo-icon-wrapper'
          mr='20px'
        >
          <Flex
            width={['60px', '60px', '60px', width <= limitSizeBetweenDesktopandMobile ? '60px' : '150px', '150px']}
          >
            <a href='/'>
              <Image
                src={logo}
                alt="logo"
                width='60px'
                height='60px'
                cursor={'pointer'}
              />
            </a>
          </Flex>
        </Flex>

        {/* navbar links(shows in desktop size) */}
        <Flex
          display={desktopDisplay}
          gap={['unset', 'unset', 'none', '15px', width < 1330 ? '20px' : '40px']}
          alignItems={['unset', 'unset', 'center', 'center']}
          py='2'
          px='2'
        >
          {
            linkArray.map((item, index) => (
              <NavLink
                key={index}
                name={item.name}
                href={item.href}
              />
            ))
          }

        </Flex>

        <Flex
          display={desktopDisplay}
          ml='15px'
        >
          <ChainIcon />

          {/* account info (only shows in desktop size) */}
          <Flex
            alignItems='center'
          >
            <Account />
          </Flex>
        </Flex>

        <Flex
          display={mobileDisplay}
          ml='20px'
        >
          <ChainIcon />
          <Flex
            alignItems='center'
            mr={'20px'}
          >
            <Account />
          </Flex>
          {/* hamburger icon(only shows in the mobile size) */}
          <Flex
            alignItems="center"
          >
            {
              isOpen ? (
                <CloseIcon
                  color={'white'}
                  fontSize="28px"
                  onClick={handleToggle}
                />
              ) : (
                <HamburgerIcon
                  color={'white'}
                  fontSize="28px"
                  onClick={handleToggle}
                />
              )
            }
          </Flex>
        </Flex>
      </Flex>

      {/* mobile navbar */}
      <Flex
        display={[
          isOpen ? 'flex' : 'none',
          isOpen ? 'flex' : 'none',
          isOpen ? 'flex' : 'none',
          isOpen && width <= limitSizeBetweenDesktopandMobile ? 'flex' : 'none',
          'none'
        ]}
        gap={'10px'}
        background={thirdColor}
      >
        <Flex
          direction={'column'}
          mx={'30px'}
          my={'15px'}
          gap='10px'
          zIndex={'2'}
        >
          {
            linkArray.map((item, index) => (
              <NavLink
                key={index}
                href={item.href}
                name={item.name}
                onClose={onClose}
              />
            ))
          }
        </Flex>
      </Flex>
    </Flex >
  )
}

export default Header;