import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { secondColor } from '../../utils/config'

export type AnnounceModalProps = {
  isOpenAnnounceModal: boolean,
  onCloseAnnounceModal(): void,
  announceText: string,
  announceLogo?: string,
  announceGif?: true,
  announceModalButtonText: string,
  announceModalButtonAction?(): void,
  announceModalCloseButtonShow?: boolean,
  announceModalNoButtonShow?: boolean
}

const AnnounceModal = ({
  isOpenAnnounceModal,
  onCloseAnnounceModal,
  announceText,
  announceLogo,
  announceGif,
  announceModalButtonText,
  announceModalButtonAction,
  announceModalCloseButtonShow = true,
  announceModalNoButtonShow,
}: AnnounceModalProps) => {

  return (
    <Modal isOpen={isOpenAnnounceModal} onClose={onCloseAnnounceModal}>
      {' '}
      <ModalOverlay
        bg='blackAlpha.10'
        backdropFilter='blur(10px) hue-rotate(10deg)'
      />
      <ModalContent
        border={'1px solid white'}
        borderRadius={'15px'}
      >
        {
          announceModalCloseButtonShow && (
            <ModalCloseButton />
          )
        }
        <ModalBody>
          <Flex
            justifyContent={'center'}
            direction={'column'}
            alignItems={'center'}
          >
            <Flex
              position={'relative'}
              direction={'column'}
              alignItems={'center'}
            >
              <Flex
                width={'308px'}
                height={'200px'}
                margin={'-8px'}
              />
              {
                announceLogo && (
                  <Image
                    width={'38px'}
                    height={'38px'}
                    src={announceLogo}
                    top={'50px'}
                    position={'absolute'}
                  />
                )
              }
              {
                announceGif && (
                  <Flex
                    className="loading-container"
                    justifyContent={'center'}
                    textAlign={'center'}
                    mt={'100px'}
                    alignItems={'center'}
                    px={'20px'}
                    position={'absolute'}
                  >
                    <section>
                      <Flex className="loader loader-1">
                        <Flex className="loader-outter" />
                        <Flex className="loader-inner" />
                      </Flex>
                    </section>
                  </Flex>
                )
              }
            </Flex>

            <Flex
              direction={'column'}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'25px'}
                lineHeight={'34px'}
                color={'white'}
                textTransform={'capitalize'}
                textAlign={'center'}
              >
                {announceText}
              </Flex>
            </Flex>

            <Flex
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'20px'}
              my={'30px'}
            >
              <Button
                onClick={() => announceModalButtonAction ? announceModalButtonAction() : onCloseAnnounceModal()}
                height={'46px'}
                minWidth={'155px'}
                width={'fit-content'}
                background={'unset'}
                borderRadius={'34px'}
                border={`1px solid ${secondColor}`}
                fontSize={'20px'}
                fontFamily={'Poppins'}
                color={'white'}
                fontWeight={'900'}
                _hover={{
                  background: secondColor,
                }}
                _selected={{
                  background: secondColor,
                }}
              >
                {announceModalButtonText}
              </Button>

              {
                announceModalNoButtonShow && (
                  <Button
                    onClick={() => onCloseAnnounceModal()}
                    height={'46px'}
                    minWidth={'120px'}
                    width={'fit-content'}
                    background={'unset'}
                    borderRadius={'34px'}
                    border={`1px solid ${secondColor}`}
                    fontSize={'20px'}
                    fontFamily={'Poppins'}
                    color={'white'}
                    fontWeight={'900'}
                    _hover={{
                      background: secondColor,
                    }}
                    _selected={{
                      background: secondColor,
                    }}
                  >
                    {'No'}
                  </Button>
                )
              }
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AnnounceModal
