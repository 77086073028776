import {
    Flex,
} from '@chakra-ui/react';
import axios, { HttpStatusCode } from 'axios';
import { connect, disconnect } from 'wagmi/actions';
import { AiFillStar, AiOutlineStar, } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { FavoriteType, TokenType, } from '../../utils/types';

type FavoriteProps = {
    tokenObjectId: string;
    favoriteRate: number;
    setFavoriteRate: (favoriteRate: number) => void;
    isRateChangable: boolean;
    cursor?: 'pointer' | 'default';
}

const Favorite = ({
    tokenObjectId,
    favoriteRate,
    setFavoriteRate,
    isRateChangable,
    cursor,
}: FavoriteProps) => {

    const handleFavoriteRate = async (index: number) => {
        try {
            const updatedFavoriteDocument: FavoriteType = (await axios.post(`/favorite/user`, {
                tokenObjectId: tokenObjectId,
                tokenType: TokenType.NFT,
                favoriteRate: index,
            })).data;
            console.log("updatedFavoriteDocument: ", updatedFavoriteDocument);
            if (updatedFavoriteDocument) {
                setFavoriteRate(updatedFavoriteDocument.favoriteRate);
                toast.success('Your favorite rate has been successfully updated');
            }
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("error in handling favorite rate: ", e);
                toast.error('Unfortunately, your operation has failed: ', e);
            }
        }
    }

    return (
        <Flex
            gap={['10px']}
            justifyContent={['center']}
        >
            {
                Array(5).fill(1).map((_, index) => (
                    <Flex
                        key={index}
                        onClick={() => { isRateChangable ? handleFavoriteRate(index + 1) : console.log('=======================') }}
                        cursor={cursor ?? 'default'}
                    >
                        {
                            favoriteRate > index ? (
                                <AiFillStar size={'30px'} color={'yellow'} />
                            ) : (
                                <AiOutlineStar size={'30px'} color={'yellow'} />
                            )
                        }
                    </Flex>
                ))
            }
        </Flex>
    )
}

export default Favorite
