import {
    Flex,
} from '@chakra-ui/react';
import { firstColor, secondColor, thirdColor } from '../../utils/config';

type CountDownRendererProps = {
    hours: number,
    minutes: number,
    seconds: number,
    days: number,
    completed: boolean,
}

const CountDownRenderer = ({
    hours,
    minutes,
    seconds,
    days,
    completed,
}: CountDownRendererProps) => {
    if (completed) {
        return (
            <Flex
                color={'white'}
            >
                Pending...
            </Flex>
        )
    } else {
        return (
            <Flex
                color={secondColor}
            >
                Time remaining : {' '}
                {days > 0 ? days + 'd ' : ''}
                {hours < 10 ? "0" + hours : hours}h{" "}
                {minutes < 10 ? "0" + minutes : minutes}m{" "}
                {seconds < 10 ? "0" + seconds : seconds}s
            </Flex>
        );
    }
};

export default CountDownRenderer;