import {
    Box,
    Flex,
    Image,
    Table,
    Tbody,
    Tr,
    Td,
    Thead,
    Th,
    Button,
} from '@chakra-ui/react'
import axios, { HttpStatusCode } from 'axios'
import { useEffect, useState, useRef } from 'react'
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { disconnect } from 'wagmi/actions'
import { newChainAttrs } from '../../utils/config'
import { useChainContext } from '../../utils/Context'
import { getFormattedDateString, getFormattedTime, } from '../../utils/formatters'
import { BidType, SaleStatus } from '../../utils/types'
import TokenImage from '../TokenImage'
import WithdrawBid from './WithdrawBid';

type BidTableProps = {
}

const BidTable = ({
}: BidTableProps) => {
    const navigate = useNavigate();
    const scroll = useRef<any>()
    const [scrollX, setScrollX] = useState<number>(0) // For detecting start scroll postion
    const [scrollEnd, setScrollEnd] = useState<boolean>(false) // For detecting end of scrolling
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [myBids, setMyBids] = useState<BidType[]>([]);
    const {
        user,
        sockets,
    } = useChainContext();

    const slide = (shift: any) => {
        scroll.current.scrollLeft += shift
        if (
            Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <= scroll.current.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }

    const scrollCheck = () => {
        setScrollX(scroll.current.scrollLeft)
        if (
            Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <= scroll.current.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
    }

    useEffect(() => {
        if (
            scroll.current &&
            scroll?.current?.scrollWidth === scroll?.current?.offsetWidth
        ) {
            setScrollEnd(true)
        } else {
            setScrollEnd(false)
        }
        return () => { }
    }, [scroll?.current?.scrollWidth, scroll?.current?.offsetWidth])

    const getMyBids = async () => {
        if (!user) return;
        try {
            setIsLoading(true);
            console.log('userPubkey', user?.publicKey);
            const myBids = (await axios.post(`/bid/search`, {
                userAddress: user?.publicKey
            })).data;
            console.log("result bid data: ", myBids);
            setMyBids(myBids);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("error in gettin your bids: ", e);
                toast.error('Unfortunately, your operation has failed: ', e);
            }
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getMyBids();
    }, [user])

    return (
        < Box
            className='my-bid-table'
        >
            <Flex
                display={['flex', 'flex', 'flex']}
                position='relative'
            >
                {
                    !scrollEnd && (
                        <Box
                            id="nextBtn"
                            className='control-button'
                            _hover={{
                                cursor: 'pointer',
                                transform: 'scale(1.2)',
                            }}
                            position={'absolute'}
                            right={['-25px']}
                            mt={'53px'}
                            zIndex={100}
                            fontSize={'44px'}
                            color={'#FFB11C'}
                            onClick={() => slide(50)}
                            cursor={'pointer'}
                        >
                            <AiFillRightCircle />
                        </Box>
                    )
                }

                {
                    scrollX !== 0 && (
                        <Box
                            _hover={{
                                cursor: 'pointer',
                                transform: 'scale(1.2)',
                            }}
                            id="prevBtn"
                            className='control-button'
                            position={'absolute'}
                            left={'-28px'}
                            mt={'53px'}
                            zIndex={100}
                            fontSize={'44px'}
                            color={'#FFB11C'}
                            onClick={() => slide(-50)}
                            cursor={'pointer'}
                        >
                            <AiFillLeftCircle />
                        </Box>
                    )
                }
            </Flex>

            {
                isLoading && (
                    <Flex
                        className="loading-container"
                        justifyContent={'center'}
                        textAlign={'center'}
                        mt={'100px'}
                        alignItems={'center'}
                        px={'20px'}
                    >
                        <section>
                            <Flex className="loader loader-1">
                                <Flex className="loader-outter" />
                                <Flex className="loader-inner" />
                            </Flex>
                        </section>
                    </Flex>
                )
            }

            {
                !isLoading && (<Flex
                    direction='column'
                    ref={scroll}
                    overflowX='scroll'
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                            background: 'transparent',
                            width: '0',
                            height: '0',
                        },
                    }}
                    onScroll={scrollCheck}
                    display={['flex', 'flex', 'flex']}
                >
                    <Flex
                        display='flex'
                        direction={'column'}
                        width='100%'
                    >
                        <Flex
                            mt={'20px'}
                            direction={'column'}
                        >
                            <Table
                                mt={'20px'}
                                gap='2'
                                className='my-bid-table'
                            >
                                <Thead
                                    fontStyle='normal'
                                    borderRadius='5px'
                                    className='my-bid-table-header'
                                >
                                    <Tr>
                                        <Th
                                            className='first-row'
                                        >
                                            #
                                        </Th>
                                        <Th
                                            ml={'10px'}
                                            className='username'
                                        >
                                            Chain Name
                                        </Th>
                                        <Th
                                        >
                                            NFT
                                        </Th>
                                        <Th
                                        >
                                            Name
                                        </Th>
                                        <Th
                                        >
                                            Bid Price
                                        </Th>
                                        <Th
                                        >
                                            Time
                                        </Th>
                                        <Th
                                        >
                                            Status
                                        </Th>
                                        {/* <Th
                                        >
                                            Withdraw
                                        </Th> */}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        myBids && myBids.map((item: BidType, index: number) => (
                                            <Tr
                                                key={index}
                                                fontFamily={'Poppins'}
                                                fontWeight={'700'}
                                                fontSize={'17px'}
                                                lineHeight={'23px'}
                                                color={'white'}
                                                textTransform={'capitalize'}
                                                pt='7px'
                                                pb='5px'
                                                borderRadius={'5px'}
                                                height='32px'
                                            >
                                                <Td
                                                    className='first-row'
                                                    borderColor='transparent'
                                                    px={['15px', '20px']}
                                                >
                                                    <Image
                                                        src={newChainAttrs[item.nft.chainId].logo}
                                                        width={'20px'}
                                                    />
                                                </Td>
                                                <Td
                                                    borderColor='transparent'
                                                    ml={'10px'}
                                                    className='username'
                                                >
                                                    {newChainAttrs[item.nft.chainId].name}
                                                </Td>
                                                <Td
                                                    ml={'60px'}
                                                    borderColor='transparent'
                                                    onClick={() => navigate(`/nft/${item.nft.contractAddress}/${item.nft.chainId}/${item.nft.tokenId}/detail`)}
                                                    cursor={'pointer'}
                                                >
                                                    <TokenImage
                                                        widthString={['50px']}
                                                        imageUrl={item.nft.imageUrl}
                                                        isCsdogeToken={item.nft.artist != null}
                                                        shouldBePointer={true}
                                                    />
                                                </Td>
                                                <Td
                                                    ml={'110px'}
                                                    borderColor='transparent'
                                                >
                                                    {item.nft.name}
                                                </Td>

                                                <Td
                                                    ml={'80px'}
                                                    borderColor='transparent'
                                                >
                                                    {item.bidPrice}
                                                </Td>

                                                <Td
                                                    ml={'80px'}
                                                    borderColor='transparent'
                                                    width={['200px']}
                                                >
                                                    {`${getFormattedDateString(new Date(item.createdAt))} ${getFormattedTime(new Date(item.createdAt))}`}
                                                </Td>

                                                <Td
                                                    ml={'60px'}
                                                    borderColor='transparent'
                                                >
                                                    {item.isWon ? 'Success' : item.auction.endTimestamp >= Math.round(Date.now() / 1000) ? 'Ongoing' : 'Failed'}
                                                </Td>
                                                {/* <Td
                                                    ml={'110px'}
                                                    borderColor='transparent'
                                                    display={item.isWon ? 'none' : 'table-cell'}
                                                >
                                                    <WithdrawBid
                                                        bidObjectId={item._id}
                                                        chainId={item.nft.chainId}
                                                        tokenId={item.nft.tokenId}
                                                        isDisabled={item.isWon || item.isWithdrawn || item.auction.status != SaleStatus.Sold}
                                                        getMyBids={getMyBids}
                                                    />
                                                </Td> */}
                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </Flex>
                    </Flex>
                </Flex>)
            }
        </Box >
    )
}

export default BidTable;