import {
  Text,
  Button,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { useWeb3Modal } from '@web3modal/react';
import { useState, useEffect, } from 'react';
import { useAccount } from 'wagmi';
import { exclamationIconInRedBg } from '../utils/assets';
import { secondColor, thirdColor } from '../utils/config';
import { useChainContext } from '../utils/Context';
import AnnounceModal from './modal/AnnounceModal';

const Account = () => {
  const { isConnected } = useAccount();
  const { open, close } = useWeb3Modal();
  const { user, } = useChainContext();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const {
    isOpen: isOpenAnnounceModal,
    onOpen: onOpenAnnounceModal,
    onClose: onCloseAnnounceModal,
  } = useDisclosure();

  return (
    <div>
      {(() => {
        if (!isConnected) {
          return (
            <Button
              onClick={open}
              variant={'solid'}
              width={['80px', '150px', '150x', '180px']}
              height={['35px', '35px', '35px', '41px']}
              borderRadius={['35px', '35px', '35px', '35px']}
              border={`1px solid ${secondColor}`}
              backgroundColor={thirdColor}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              <Text
                fontSize={['14px', '14px', '16px']}
                fontFamily={'Poppins'}
                fontWeight="700px"
                lineHeight={'25px'}
              >
                {
                  width < 480 ? 'Connect' : 'Connect Wallet'
                }
              </Text>
            </Button>
          );
        } else {
          return (
            <Button
              onClick={open}
              variant={'solid'}
              width={['120px', '150px', '150x', '180px']}
              height={['35px', '35px', '35px', '41px']}
              borderRadius={['35px', '35px', '35px', '35px']}
              border={`1px solid ${secondColor}`}
              backgroundColor={thirdColor}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              <Text
                fontSize={['14px', '14px', '16px']}
                fontFamily={'Poppins'}
                fontWeight="700px"
                lineHeight={'25px'}
              >
                {
                  user ? (
                    width < 480 ? 'Connected'
                      : user.username != `name-${user.publicKey}` ?
                        (user.username.length > 15 ? user.username.slice(0, 8) + '...' + user.username.slice(user.username.length - 6) : user.username)
                        : 'Connected'
                  ) : (
                    'Sign In'
                  )
                }
              </Text>
            </Button>
          );
        }

      })()}
    </div>
  );
};

export default Account;