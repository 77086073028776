import { createContext, useContext } from "react";
import { io, Socket } from "socket.io-client"
import { UserType } from "./types";

export type Sockets = {
    userSocket?: Socket,
    nfts?: Socket,
    sfts?: Socket,
    fixedSales?: Socket,
    bids?: Socket,
    auctions?: Socket,
    groups?: Socket,
}

type ChainContextType = {
    sockets: Sockets,
    setSockets: (sockets: Sockets) => void,
    user: UserType | null,
    setUser: (user: UserType | null) => void,
    selectedTokenObjectIds: string[],
    setSelectedTokenObjectIds: (selectedTokenObjectIds: string[]) => void,
};

export const ChainContext = createContext<ChainContextType>({
    sockets: {
        userSocket: undefined,
        nfts: undefined,
        sfts: undefined,
        fixedSales: undefined,
        bids: undefined,
        auctions: undefined,
        groups: undefined,
    },
    setSockets: () => { },
    user: null,
    setUser: () => { },
    selectedTokenObjectIds: [],
    setSelectedTokenObjectIds: () => { }
});

export const useChainContext = () => useContext(ChainContext);