import {
    Flex,
} from '@chakra-ui/react';
import { useState, useEffect, } from 'react';
import { PINATA_GATEWAY_URL } from '../../utils/config';
import { getFullUrl } from '../../utils/formatters';

type TokenImageProps = {
    widthString: string[]
    imageUrl: string,
    hasBorder?: boolean,
    isCsdogeToken: boolean,
    shouldShowCheckbox?: boolean | undefined,
    shouldBePointer?: boolean | undefined,
    onClick?: () => void
}

const TokenImage = ({
    widthString,
    imageUrl,
    hasBorder,
    isCsdogeToken,
    shouldShowCheckbox,
    shouldBePointer,
    onClick,
}: TokenImageProps) => {
    const [isHeightBiggerThanWidth, setIsHeightBiggerThanWidth] = useState<boolean>(false);
    const [image, setImage] = useState<any>(undefined)

    const loadImage = (imageUrl: string) => {
        const img = new Image();
        img.src = getFullUrl((isCsdogeToken ? process.env.REACT_APP_PINATA_GATEWAY_URL : PINATA_GATEWAY_URL) ?? '', imageUrl);

        img.onload = () => {
            setIsHeightBiggerThanWidth(img.height > img.width ? true : false);
            setImage(img);
        };
        img.onerror = (err: any) => {
            console.log("img error");
            console.error(err);
        };
    };

    useEffect(() => {
        loadImage(imageUrl);
    }, [])

    return (
        <Flex
            height={widthString}
            direction={isHeightBiggerThanWidth ? 'row' : 'column'}
            justifyContent={isHeightBiggerThanWidth ? 'unset' : 'center'}
            border={hasBorder ? '1px solid white' : 'unset'}
            cursor={(shouldShowCheckbox || shouldBePointer) ? 'pointer' : 'default'}
            onClick={onClick}
        >
            <Flex
                className='token-image'
                justifyContent={'center'}
                background={'transparent'}
                width={widthString}
            >
                <img
                    src={getFullUrl((isCsdogeToken ? process.env.REACT_APP_PINATA_GATEWAY_URL : PINATA_GATEWAY_URL) ?? '', imageUrl)}
                />
            </Flex>
        </Flex>
    )
}

export default TokenImage;
