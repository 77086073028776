import {
  Flex,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import copy from 'copy-text-to-clipboard';
import axios, { HttpStatusCode } from 'axios';
import { useEffect, useState, } from 'react';
import Countdown from 'react-countdown';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Favorite from '../../components/Favorite';
import CancelListing from '../../components/ListForSale/CancelListing';
import CompleteListing from '../../components/ListForSale/CompleteListing';
import CountDownRenderer from '../../components/ListForSale/CountDownRenderer';
import UpdateListing from '../../components/ListForSale/UpdateListing';
import { durationOptions, explorers, newChainAttrs, nftMintContractAddresses, secondColor, thirdColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { getEllipsisTxt, getFormattedDateString, getFormattedTime } from '../../utils/formatters';
import { AuctionType, BidType, TokenSaleStatus, NftType, SaleStatus, UserType, BidderInfoType } from '../../utils/types';
import { disconnect } from 'wagmi/actions';
import TokenImageWrapper from '../../components/ListForSale/TokenImageWrapper';

const ListForSale = () => {
  const { chainId, tokenId, contractAddress } = useParams();
  const {
    user,
    sockets,
  } = useChainContext();
  const [nftCategory, setNftCategory] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nftInfo, setNftInfo] = useState<NftType>();
  const [auctionInfo, setAuctionInfo] = useState<AuctionType>();
  const [biddersInfo, setBiddersInfo] = useState<BidderInfoType[]>([]);
  const [sellType, setSellType] = useState<string>('0');
  const [price, setPrice] = useState<number | null>(null);
  const [durationInSeconds, setDurationInSeconds] = useState<number>();
  const [favoriteRate, setFavoriteRate] = useState<number>(0);
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const getNFTForTokenId = async () => {
    try {
      setIsLoading(true);
      const [nftForTokenIdRes, biddersRes] = await Promise.all([
        axios.get(`/nft/${chainId}/${tokenId}/${contractAddress}`),
        axios.get(`/bid/${chainId}/${tokenId}/${contractAddress}`)
      ]);

      const [nftForTokenId, bidders] = [nftForTokenIdRes.data, biddersRes.data];

      console.log('bidders', bidders);
      console.log("nftForTokenId: ", nftForTokenId);
      setNftInfo(nftForTokenId);
      setBiddersInfo(bidders);

      const sellType = ((nftForTokenId?.tokenSaleStatus ?? 0) >= 1) ? (nftForTokenId!.tokenSaleStatus - 1) : 0;
      setSellType(sellType.toString());

      if (nftForTokenId?.tokenSaleStatus == TokenSaleStatus.InAuction) {
        getAuctionInfo(nftForTokenId.currentAuction);
      }

      // return nftForTokenId;
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
        disconnect();
      } else {
        console.log("error in getting nft: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
    }
    setIsLoading(false);
  }

  const getAuctionInfo = async (auctionObjectId: string) => {
    try {
      const auction = ((await axios.post(`/auction/getFromAuctionObjectId`, {
        auctionObjectId,
      })).data);
      console.log("auction: ", auction);
      setAuctionInfo(auction);
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
        disconnect();
      } else {
        console.log("error in getting auction info: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
    }
  }

  useEffect(() => {
    getNFTForTokenId();
  }, [shouldRender]);

  const handleSellType = (value: string) => {
    if (nftInfo?.tokenSaleStatus == TokenSaleStatus.NotInSale) {
      setSellType(value);
    } else {
      toast.error('This nft is already in sale');
    }
  }

  const getFavoriteRate = async (nftObjectId: string, user: UserType | null) => {
    if (user) {
      try {
        const favoriteRate = (await axios.get(`/favorite/user/${nftObjectId}/${user?._id}`)).data;
        console.log("favorite rate: ", favoriteRate ? favoriteRate : 0);
        setFavoriteRate(favoriteRate);
      } catch (e: any) {
        if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
          toast.error('Please sign in again');
          disconnect();
        } else {
          console.log("error in getting my favorite rate:  ", e);
          toast.error('Unfortunately, your operation has failed: ', e);
        }
      }
    }
  }

  useEffect(() => {
    if (nftInfo && user) {
      getFavoriteRate(nftInfo?._id, user);
    }
  }, [nftInfo, user])

  return (
    <Flex
      className='list-for-sale-page-wrapper-component'
      width={'100%'}
      justifyContent={'center'}
    >
      {
        isLoading && (
          <Flex
            className="loading-container"
            justifyContent={'center'}
            mt={'100px'}
            alignItems={'center'}
          >
            <section>
              <Flex className="loader loader-1">
                <Flex className="loader-outter" />
                <Flex className="loader-inner" />
              </Flex>
            </section>
          </Flex>
        )
      }
      {
        !isLoading && nftInfo &&
        nftInfo.owner.toLowerCase() == user?.publicKey?.toLowerCase() &&
        chainId != undefined &&
        Object.keys(newChainAttrs).includes(chainId) && (
          <Flex
            className='list-for-sale-page-component'
            justifyContent={'center'}
            direction={'column'}
            pt={['30px']}
            pb={['50px', '50px', '100px']}
            color={'white'}
          >
            <Flex
              direction={['column', 'column', 'column', 'row']}
              mt={['10px']}
              mb={['20px']}
              gap={['30px', '30px', '100px']}
            >
              <Flex
                className='sell-infos'
                direction={'column'}
                width={['300px', '350px', '400px', '400px']}
                justifyContent={'center'}
                gap={['50px']}
              >
                <Flex
                  direction={['column']}
                  gap={['5px']}
                  display={['flex', 'flex', 'flex', 'none',]}
                >
                  <TokenImageWrapper
                    imageUrl={nftInfo?.imageUrl}
                    name={nftInfo?.name}
                    nftAddress={nftInfo?.contractAddress}
                    tokenId={nftInfo?.tokenId}
                    chainId={nftInfo?.chainId}
                    isDesktop={false}
                    isCsdogeToken={nftInfo?.artist != null}
                    isOnSale={nftInfo?.tokenSaleStatus != TokenSaleStatus.NotInSale}
                  />
                  {
                    nftInfo && user && (
                      <Favorite
                        tokenObjectId={nftInfo._id}
                        favoriteRate={favoriteRate ?? 0}
                        setFavoriteRate={setFavoriteRate}
                        isRateChangable={true}
                        cursor='pointer'
                      />
                    )
                  }
                </Flex>

                <Flex
                  fontFamily={'Poppins'}
                  fontWeight={'700'}
                  fontSize={'30px'}
                  lineHeight={'30px'}
                  color={'white'}
                  textTransform={'capitalize'}
                  direction={'column'}
                >
                  List for sale

                  <Flex
                    gap={'5px'}
                    fontSize={'18px'}
                  >
                    <Flex
                      className='nft-owner'
                      fontFamily={'Poppins'}
                      fontWeight={'700'}
                      lineHeight={'30px'}
                      color={'white'}
                      textTransform={'capitalize'}
                    >
                      SC :
                    </Flex>
                    <Flex
                      onClick={() => copy(nftInfo.owner)}
                      cursor={'copy'}
                      fontFamily={'Poppins'}
                      fontWeight={'700'}
                      lineHeight={'30px'}
                      color={'white'}
                      textTransform={'capitalize'}
                    >
                      <a
                        href={`${explorers[chainId]}/address/${nftMintContractAddresses[chainId]}`}
                        target='_blank' rel="noopener noreferrer"
                        className='underline'
                      >
                        {`${contractAddress?.slice(0, 6)}...${contractAddress?.slice(-6)}`}
                      </a>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  direction={'column'}
                  gap={['10px']}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'20px'}
                    lineHeight={'30px'}
                    color={'white'}
                  >
                    Choose a type of sale
                  </Flex>

                  <RadioGroup
                    mt={'10px'}
                    onChange={handleSellType}
                    value={sellType}
                  >
                    <Stack direction='row'>
                      <Radio value='0' fontFamily={'Poppins'}>Fixed Price</Radio>
                      <Radio value='1' fontFamily={'Poppins'}>Timed Auction</Radio>
                    </Stack>
                  </RadioGroup>
                </Flex>

                {/* showing in fixed sale listing, auction, cancel and auction listing */}
                <Flex
                  className="sell-price"
                  direction={'column'}
                >
                  <Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'20px'}
                    lineHeight={'30px'}
                    textTransform={'capitalize'}
                    mb={['10px']}
                  >
                    Set a price
                  </Flex>

                  { // auction
                    sellType == '1' && (
                      <Flex
                        fontFamily={'Poppins'}
                        fontWeight={'700'}
                        fontSize={'15px'}
                        lineHeight={'30px'}
                        textTransform={'capitalize'}
                      >
                        Starting price
                      </Flex>
                    )
                  }

                  <Flex
                    alignItems={'end'}
                  >
                    <Input
                      value={price == null ? '' : price}
                      type={'number'}
                      variant="flushed"
                      placeholder='Amount'
                      borderBottom={`2px solid ${secondColor}`}
                      onChange={(e) => setPrice(e?.target?.value ? Number(e?.target?.value) : null)}
                      fontFamily={'Poppins'}
                      _focusVisible={{
                        boxShadow: 'none',
                      }}
                    />

                    <Flex
                    >
                      {newChainAttrs[chainId].nativeToken}
                    </Flex>
                  </Flex>
                </Flex>

                { // shows when already in auction phase
                  nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction && (
                    <Flex
                      direction={['column']}
                      gap={['20px']}
                    >
                      <Flex
                        fontFamily={'Poppins'}
                        fontWeight={'700'}
                        fontSize={'20px'}
                        lineHeight={'30px'}
                        color={'white'}
                        textTransform={'capitalize'}
                      >
                        Start price: {auctionInfo?.startPrice} {newChainAttrs[chainId].nativeToken}
                      </Flex>



                      {
                        (nftInfo.currentBidderCount ?? 0) > 0 ? (
                          <>
                            <Flex
                              fontFamily={'Poppins'}
                              fontWeight={'700'}
                              fontSize={'20px'}
                              lineHeight={'30px'}
                              color={'white'}
                              textTransform={'capitalize'}
                            >
                              Top Bid Price: {auctionInfo?.topBidPrice} {newChainAttrs[chainId].nativeToken}
                            </Flex>

                            <Flex
                              fontFamily={'Poppins'}
                              fontWeight={'700'}
                              fontSize={'20px'}
                              lineHeight={'30px'}
                              color={'white'}
                              textTransform={'capitalize'}
                              onClick={() => copy(auctionInfo?.topBidder ?? '')}
                            >
                              Top Bidder: {auctionInfo?.topBidder ? getEllipsisTxt(auctionInfo?.topBidder) : ''}
                            </Flex>
                          </>
                        ) : (
                          <Flex
                            fontFamily={'Poppins'}
                            fontWeight={'700'}
                            fontSize={'20px'}
                            lineHeight={'30px'}
                            color={'white'}
                            textTransform={'capitalize'}
                            mt={['50px']}
                          >
                            No one made bid yet !
                          </Flex>
                        )
                      }
                    </Flex>
                  )
                }

                {
                  nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction && auctionInfo && (<Flex
                    fontFamily={'Poppins'}
                    fontWeight={'700'}
                    fontSize={'20px'}
                    lineHeight={'30px'}
                    color={'white'}
                    textTransform={'capitalize'}
                  >
                    <Countdown date={Number(auctionInfo?.endTimestamp ?? 0) * 1000} renderer={CountDownRenderer} />
                  </Flex>)
                }

                { // shows in listing auction
                  sellType == '1' && nftInfo.tokenSaleStatus != TokenSaleStatus.InAuction && (
                    <Flex
                      className='sell-duration-select-wrapper'
                      direction={'column'}
                      gap={['10px']}
                    >
                      <Flex
                        fontFamily={'Poppins'}
                        fontWeight={'700'}
                        fontSize={'20px'}
                        lineHeight={'30px'}
                        color={'white'}
                        textTransform={'capitalize'}
                        className='title'
                      >
                        Duration
                      </Flex>

                      <Select
                        placeholder='Select Duration'
                        border={'2px solid #739AF0 !important'}
                        fontFamily={'Poppins'}
                        fontWeight={700}
                        fontSize={'18px'}
                        lineHeight={'25px'}
                        height={'41px'}
                        borderRadius={'5px'}
                        onChange={(e) => {
                          setDurationInSeconds(isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                        }}
                        value={nftCategory}
                      >
                        {
                          durationOptions.map((item, index) => (
                            <option
                              value={item.duration}
                              key={index}
                              //@ts-ignore
                              background={`${thirdColor} !important`}
                              cursor={'pointer'}
                            >
                              {item.label}
                            </option>
                          ))
                        }
                      </Select>
                    </Flex>
                  )
                }

                { // shows when already in sale phase
                  nftInfo?.tokenSaleStatus == TokenSaleStatus.InFixedSale && (
                    <Flex
                      fontFamily={'Poppins'}
                      fontWeight={'700'}
                      fontSize={'20px'}
                      lineHeight={'30px'}
                      color={'white'}
                      textTransform={'capitalize'}
                    >
                      Current price: {nftInfo?.price} {newChainAttrs[chainId].nativeToken}
                    </Flex>
                  )
                }
              </Flex>

              <Flex
                direction={['column']}
              >
                <TokenImageWrapper
                  imageUrl={nftInfo?.imageUrl}
                  name={nftInfo?.name}
                  nftAddress={nftInfo?.contractAddress}
                  tokenId={nftInfo?.tokenId}
                  chainId={nftInfo?.chainId}
                  isDesktop={true}
                  isCsdogeToken={nftInfo.artist != null}
                  isOnSale={nftInfo?.tokenSaleStatus != TokenSaleStatus.NotInSale}
                />
                <Flex
                  justifyContent={['center']}
                  mt={['5px']}
                  display={['none', 'none', 'none', 'flex',]}
                >
                  {
                    nftInfo && user && (
                      <Favorite
                        tokenObjectId={nftInfo._id}
                        favoriteRate={favoriteRate ?? 0}
                        setFavoriteRate={setFavoriteRate}
                        isRateChangable={true}
                        cursor='pointer'
                      />
                    )
                  }
                </Flex>

              </Flex>
            </Flex>

            {/* Bidders table */}
            {
              nftInfo?.tokenSaleStatus == TokenSaleStatus.InAuction && (nftInfo?.currentBidderCount || 0) > 0 && (
                <Flex>
                  <Table>
                    <Thead
                      fontStyle='normal'
                      borderRadius='5px'
                    >
                      <Tr>
                        <Th
                          color={'white'}
                          fontFamily={'Poppins'}
                          fontSize={'14px'}
                          px={0}
                        >
                          Bidder Address
                        </Th>
                        <Th
                          color={'white'}
                          fontFamily={'Poppins'}
                          fontSize={'14px'}
                          textAlign={'center'}
                          px={0}
                        >
                          Amount
                        </Th>
                        <Th
                          color={'white'}
                          fontFamily={'Poppins'}
                          fontSize={'14px'}
                          textAlign={'center'}
                          px={0}
                        >
                          Time
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {
                        biddersInfo.map((item, index) => (
                          <Tr
                            key={index}
                            fontFamily={'Poppins'}
                            fontWeight={'700'}
                            fontSize={'14px'}
                            color={'white'}
                            borderRadius={'5px'}
                          >
                            <Td
                              borderColor='transparent'
                              px={0}
                              py={'5px'}
                            >
                              {item.bidder}
                            </Td>
                            <Td
                              borderColor='transparent'
                              textAlign={'center'}
                              px={0}
                              py={'5px'}
                            >
                              {item.bidPrice}
                            </Td>
                            <Td
                              borderColor='transparent'
                              textAlign={'center'}
                              px={0}
                              py={'5px'}
                            >
                              {`${getFormattedDateString(new Date(item.createdAt))} ${getFormattedTime(new Date(item.createdAt))}`}
                            </Td>
                          </Tr>
                        ))
                      }
                    </Tbody>
                  </Table>
                </Flex>
              )
            }

            {/* Managing buttons */}
            <Flex
              gap={['20px', '20px', '30px',]}
              mt={['50px']}
              justifyContent={'center'}
              direction={['column', 'column', 'column', 'row']}
            >
              {
                nftInfo?.tokenSaleStatus == TokenSaleStatus.NotInSale ? (
                  <CompleteListing
                    tokenId={nftInfo.tokenId}
                    nftContractAddress={contractAddress}
                    isFixedSale={sellType == '0' ? true : false}
                    price={price}
                    nftObjectId={nftInfo._id}
                    shouldRender={shouldRender}
                    setShouldRender={setShouldRender}
                    durationInSeconds={durationInSeconds}
                  />
                ) : (
                  (
                    (nftInfo.tokenSaleStatus == TokenSaleStatus.InFixedSale) ||
                    (nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction && nftInfo.currentBidderCount == 0)
                  ) ? (
                    <UpdateListing
                      tokenId={nftInfo.tokenId}
                      nftContractAddress={contractAddress}
                      isFixedSale={sellType == '0' ? true : false}
                      price={price}
                      nftObjectId={nftInfo._id}
                      shouldRender={shouldRender}
                      setShouldRender={setShouldRender}
                    />
                  ) : null
                )
              }

              {
                (
                  (nftInfo.tokenSaleStatus == TokenSaleStatus.InFixedSale) ||
                  (nftInfo.tokenSaleStatus == TokenSaleStatus.InAuction && nftInfo.currentBidderCount == 0)
                ) && (
                  <CancelListing
                    tokenId={nftInfo.tokenId}
                    nftContractAddress={contractAddress}
                    isFixedSale={sellType == '0' ? true : false}
                    nftObjectId={nftInfo._id}
                    shouldRender={shouldRender}
                    setShouldRender={setShouldRender}
                  />
                )
              }
            </Flex>
          </Flex>
        )
      }
    </Flex>
  )
}

export default ListForSale
