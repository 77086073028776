import { Chain } from '@wagmi/core';
import Home from "../pages/Home";
import Mint from "../pages/Mint";
import MyNFTs from "../pages/MyNFTs";
import MyProfile from "../pages/MyProfile";
import MyBid from "../pages/MyBid";

import {
  avalancheLogo,
  bscLogo,
  polygonLogo,
  arbitrumLogo,
} from "./assets";
import { ChainStatus, TokenStatus, OwnerStatus, SortStatus, TextStatus } from "./types";

// testnet
export const fujiChainId = 43113;
export const bscTestnetChainId = 97;
export const mumbaiChainId = 80001;
export const arbGoerliChainId = 421613;
// mainnet
export const avalancheChainId = 43114;
export const bscChainId = 56;
export const polygonChainId = 137;
export const arbChainId = 42161;

// color
export const firstColor = '#050023'; // background
export const secondColor = '#99b7ff'; // button border
export const thirdColor = '#2E2E49'; // button background

const avalancheChain = {
  chainId: avalancheChainId,
  logo: avalancheLogo,
  name: 'Avalanche',
  mainnetName: "Avalanche Mainnet",
  alt: 'avalanche-logo',
  nativeToken: "AVAX",
};

const bscChain = {
  chainId: bscChainId,
  logo: bscLogo,
  name: 'BNB Chain',
  mainnetName: "BSC Mainnet",
  alt: 'bsc-logo',
  nativeToken: "BNB",
};

const polygonChain = {
  chainId: polygonChainId,
  logo: polygonLogo,
  name: 'Polygon',
  mainnetName: "Polygon Mainnet",
  alt: 'polygon-logo',
  nativeToken: "MATIC",
};

const arbitrumChain = {
  chainId: arbChainId,
  logo: arbitrumLogo,
  name: 'Arbitrum',
  mainnetName: "Arbitrum Mainnet",
  alt: 'arbitrum-logo',
  nativeToken: "ETH",
};

export const chainAttrs = {
  mainnet: [
    avalancheChain,
    bscChain,
    polygonChain,
    arbitrumChain,
  ],
  testnet: [
    {
      ...avalancheChain,
      chainId: fujiChainId,
    },
    {
      ...bscChain,
      chainId: bscTestnetChainId,
    },
    {
      ...polygonChain,
      chainId: mumbaiChainId,
    },
    {
      ...arbitrumChain,
      chainId: arbGoerliChainId,
    },
  ],
};

export const newChainAttrs: { [k: string]: any } = {
  [avalancheChainId]: avalancheChain,
  [bscChainId]: bscChain,
  [polygonChainId]: polygonChain,
  [arbChainId]: arbitrumChain,
  [fujiChainId]: { ...avalancheChain, chainId: fujiChainId },
  [bscTestnetChainId]: { ...bscChain, chainId: bscTestnetChainId },
  [mumbaiChainId]: { ...polygonChain, chainId: mumbaiChainId },
  [arbGoerliChainId]: { ...arbitrumChain, chainId: arbGoerliChainId },
};

export const linkArray = [
  {
    name: 'Home',
    href: '/',
    component: Home,
  },
  {
    name: 'Mint',
    href: '/mint',
    component: Mint,
  },
  {
    name: 'My NFTs',
    href: '/my-nfts',
    component: MyNFTs,
  },
  {
    name: 'My Profile',
    href: '/my-profile',
    component: MyProfile,
  },
  {
    name: 'My Bid',
    href: '/my-bid',
    component: MyBid,
  },
];

export const sortByItems = [
  'Volume: High to Low',
  'Volume: Low to High',
  'Deadline: Closest to Furthest',
  'Deadline: Furthest to Closest',
  'State: active',
];


export const supportedChainIds = {
  "testnet": [fujiChainId, bscTestnetChainId, mumbaiChainId, arbGoerliChainId],
  "mainnet": [avalancheChainId, bscChainId, polygonChainId, arbChainId],
}

export const signContractAddresses: { [k: string]: any } = {
  [fujiChainId]: '0xB591F69Fe204E2D477287583A1eD8f5Ba057d4c1',
  [bscTestnetChainId]: '0x20b965d14A6Eb27662A51f46C91d78247e16cD05',
  [mumbaiChainId]: '0x56aF6A154D0a504dC9a6ef0382D2EDCC82b77E48',
  [arbGoerliChainId]: '0x7c1a3C12E30B271A0177c1579D77B33e2666BCf4',
  [avalancheChainId]: '0xd8eC40855c984887C4598669c71D2eaC672B94B1', // avalanche
  [bscChainId]: '0x92ec28e89EC628481bd20BDB22a26294BCbcBf43', // bsc
  [polygonChainId]: '0x0429e2f609119D777e8E6D255342488FC1a8030C', // polygon
  [arbChainId]: '0x00aB43c379d6D9674dD6959d8b878990a1f322DE', // arbitrum
}

export const nftMintContractAddresses: { [k: string]: any } = {
  [fujiChainId]: '0xEb19b77b983b248554CC4ad1a8FCfba849c80dAc',
  [bscTestnetChainId]: '0x8B57Cbf3ac87e196173934883cf1c669E4F8D1a0',
  [mumbaiChainId]: '0xd98bfD208afd62c6CD6021C5E80A41DC8F30e78d',
  [arbGoerliChainId]: '0x092d2C11508703b382072786a82CAd3A77C9B35C',
  [avalancheChainId]: '0x4a5540165C9d19eCe0eF7d7cF6E8a133B5D7aBc9', // avalanche
  [bscChainId]: '0xD2cE76e6b15CF41268Ee2814aBFb3615350Eb1ED', // bsc
  [polygonChainId]: '0xdAa7F5ca4315EEa09711788A295522e97Dc51cf8', // polygon
  [arbChainId]: '0x27130360B99738b7a1856975c7B9D479df7EEd08', // arbitrum
}

export const nftSellContractAddresses: { [k: string]: any } = {
  [fujiChainId]: '0xec9d282Dd42ffcBB1782679352B5171566689774',
  [bscTestnetChainId]: '0x523987DB4ca54fC9eB5Bdb2F9780770fA77f6382',
  [mumbaiChainId]: '0xDF0EFFE64cA9727bd49B77C2fd50d42Be7F09BF5',
  [arbGoerliChainId]: '0x094f07b6E61A6e8b1de62471CFBc1E732Fe22676',
  [avalancheChainId]: '0x0cD8b39FB20800Bee963754FF450aEfC869Cbb36', // avalanche
  [bscChainId]: '0x54CDDCf791109b95562Fda318ABa8d40F748ea9A', // bsc
  [polygonChainId]: '0xD54b1519702D141a65325dB600b4a494D785D570', // polygon
  [arbChainId]: '0x40161C97D18A245a961541Ab5211acC77706020d', // arbitrum
}

export const sftMintContractAddresses: { [k: string]: any } = {
  [fujiChainId]: '',
  [bscTestnetChainId]: '',
  [mumbaiChainId]: '',
  [arbGoerliChainId]: '',
  [avalancheChainId]: '',
  [bscChainId]: '',
  [polygonChainId]: '',
  [arbChainId]: '',
}

export const sftSellContractAddresses: { [k: string]: any } = {
  [fujiChainId]: '',
  [bscTestnetChainId]: '',
  [mumbaiChainId]: '',
  [arbGoerliChainId]: '',
  [avalancheChainId]: '',
  [bscChainId]: '',
  [polygonChainId]: '',
  [arbChainId]: '',
}

export const explorers: { [k: string | number]: any } = {
  [fujiChainId]: 'https://testnet.snowtrace.io',
  [bscTestnetChainId]: 'https://testnet.bscscan.com',
  [mumbaiChainId]: 'https://mumbai.polygonscan.com',
  [arbGoerliChainId]: 'https://goerli.arbiscan.io',
  [avalancheChainId]: 'https://snowtrace.io',
  [bscChainId]: 'https://bscscan.com',
  [polygonChainId]: 'https://polygonscan.com',
  [arbChainId]: 'https://arbiscan.io',
}

export const secondsIn10Mins = 600
export const secondsIn20Mins = 1200
export const secondsInHalfHour = 1800
export const secondsIn12Hours = 60 * 60 * 12;
export const secondsInDay = 60 * 60 * 24;
export const secondsInWeek = secondsInDay * 7;
export const secondsInMonth = secondsInDay * 30;

export const messageToSign = "Login to the Csdoge Platform";

export const durationOptions = [
  {
    label: '1 day',
    duration: secondsInDay,
  },
  {
    label: '3 days',
    duration: 3 * secondsInDay,
  },
  {
    label: '7 days',
    duration: secondsInWeek,
  },
  {
    label: '1 month',
    duration: secondsInMonth,
  },
  {
    label: '3 months',
    duration: 3 * secondsInMonth,
  },
  {
    label: '6 months',
    duration: 6 * secondsInMonth,
  },
  // for test
  {
    label: '10 minutes',
    duration: secondsIn10Mins,
  },
  {
    label: '30 minutes',
    duration: secondsInHalfHour,
  },
]

export const chainStatuses = [
  {
    label: 'All',
    value: ChainStatus.All,
  },
  {
    label: 'Bsc',
    value: ChainStatus.Bsc,
  },
  {
    label: 'Polygon',
    value: ChainStatus.Polygon,
  },
  {
    label: 'Avalanche',
    value: ChainStatus.Avalanche,
  },
  {
    label: 'Arbitrum',
    value: ChainStatus.Arbitrum,
  },
]

export const tokenStatuses = [
  {
    label: 'Any',
    value: TokenStatus.Any,
  },
  {
    label: 'Fixed Sale',
    value: TokenStatus.FixedSale,
  },
  {
    label: 'Auction',
    value: TokenStatus.Auction,
  },
  {
    label: 'New',
    value: TokenStatus.New,
  },
]

export const ownerStatuses = [
  {
    label: 'All',
    value: OwnerStatus.All,
  },
  {
    label: 'Me',
    value: OwnerStatus.Me,
  },
]

export const textStatuses = [
  {
    label: 'Name',
    value: TextStatus.FindByName,
  },
  {
    label: 'Artist',
    value: TextStatus.FindByArtist,
  },
  {
    label: 'Category',
    value: TextStatus.FindByCategory,
  },
  {
    label: 'Description',
    value: TextStatus.FindByDescription,
  },
]

export const sortStatuses = [
  {
    label: 'Price Low To High',
    value: SortStatus.PriceLow2High,
  },
  {
    label: 'Price High To Low',
    value: SortStatus.PriceHigh2Low,
  },
  {
    label: 'Recently Listed',
    value: SortStatus.RecentlyListed,
  },
  {
    label: 'Most Favorite',
    value: SortStatus.MostFavorite,
  },
  {
    label: 'Recently Sold',
    value: SortStatus.RecentlySold,
  },
  {
    label: 'Recently Created',
    value: SortStatus.RecentlyCreated,
  },
]

export const categoryList = [
  'Fantasy',
  'Realism',
  'Graffiti',
  'Line art',
  'Animal',
  'Portrait',
  'Anime',
  'Landscape',
  'Abstract',
  'Feelings',
  'Cartoon',
  'Drawing',
  'Oil Painting',
  'Painting',
  'Meme',
  'Crypto',
  'Love',
  'Architecture',
  'Sculpture',
  'Human',
  'Photography',
  'Video',
  'Fine art',
  'Crafts',
  'Caricature',
  'New age',
  'Religion',
  'Manga',
]

export const pageItems = 2;

export const PINATA_GATEWAY_URL = 'https://gateway.pinata.cloud';
export const CONTRACT_OWNER = '0x9DF1C3d8Ee1A51cb2A602FfF3144C7319139bbe2';