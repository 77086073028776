import {
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { useAccount, useNetwork } from 'wagmi';
import { disconnect, writeContract, switchNetwork } from '@wagmi/core';
import { secondColor, nftSellContractAddresses, thirdColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { useState, } from 'react';
import AnnounceModal from '../modal/AnnounceModal';
import { checkIconInGreenBg, nftSellContractAbi } from '../../utils/assets';
import { toast } from 'react-toastify';
import { getSignature } from '../../utils/interact';
import axios, { HttpStatusCode } from 'axios';
import { ethers } from 'ethers';
import { useParams } from 'react-router-dom';
import { delay } from '../../utils/formatters';

type MakeBidProps = {
    tokenId: number;
    contractAddress: string | undefined;
    myBidPrice: number | null;
    currentTopBid: number;
    nftObjectId: string | null;
    auctionObjectId: string;
    isDisabled: boolean;
    getNFTForTokenId: () => void;
}

const MakeBid = ({
    tokenId,
    contractAddress,
    myBidPrice,
    currentTopBid,
    nftObjectId,
    auctionObjectId,
    isDisabled,
    getNFTForTokenId,
}: MakeBidProps) => {
    const { chainId } = useParams();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { user } = useChainContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenMakeBidSuccessModal,
        onOpen: onOpenMakeBidSuccessModal,
        onClose: onCloseMakeBidSuccessModal,
    } = useDisclosure();

    const makeBidWithSignature = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[chainId!],
                abi: nftSellContractAbi,
                functionName: 'makeBid',
                value: ethers.utils.parseEther(myBidPrice!.toString()).toBigInt(),
                args: [
                    contractAddress,
                    tokenId,
                    signature,
                ],
            });

            console.log("transaction hash: ", tx.hash);

            await axios.post('/bid/registerBid', {
                nftAddress: contractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
                bidPrice: myBidPrice,
                txh: tx.hash
            });

            await delay(20000);

            onOpenMakeBidSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("make bid error: ", e);
                toast.error('Your operation failed: ', e);
            }

            setIsProcessing(false);
        }
    }

    const makeBid = async () => {
        if (!chain || !user) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (parseInt(chainId ?? '') != chain?.id) {
            await switchNetwork({ chainId: Number(chainId) });
        }
        if (!myBidPrice || myBidPrice < 0) {
            toast.error('Please type price at first');
            return;
        }
        if (myBidPrice <= currentTopBid) {
            toast.error('Please type higher price than current top bid price');
            return;
        }
        setIsProcessing(true);
        const signatureResult = await getSignature(
            user!,
            Number(chainId),
            contractAddress!,
            tokenId,
            1, // for compatibility with sign contract
        )
        if (!signatureResult?.isSuccess) {
            toast.error('Signature Error');
            setIsProcessing(false);
            return;
        }

        await makeBidWithSignature(signatureResult.signature);
    }

    return (
        <>
            <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'7px'}
                mt={'30px'}
                onClick={() => makeBid()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['240px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                isDisabled={isDisabled}
                fontWeight={'900'}
                _hover={{
                    background: secondColor,
                }}
                _selected={{
                    background: secondColor,
                }}
            >
                Make Bid
            </Button>
            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenMakeBidSuccessModal}
                onCloseAnnounceModal={() => {
                    getNFTForTokenId();
                    onCloseMakeBidSuccessModal();
                }}
                announceText={`Bid has been successfully made`}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />
        </>
    )
}

export default MakeBid;