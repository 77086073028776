import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios, { HttpStatusCode } from 'axios';
import { ethers } from 'ethers';
import {
    Button,
    useDisclosure,
} from '@chakra-ui/react';
import { useNetwork, useAccount } from 'wagmi';
import { disconnect, writeContract, switchNetwork } from '@wagmi/core';
import AnnounceModal from '../modal/AnnounceModal';
import {
    secondColor,
    nftSellContractAddresses,
    thirdColor,
} from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { getSignature } from '../../utils/interact';
import { delay } from '../../utils/formatters';
import { checkIconInGreenBg, nftSellContractAbi } from '../../utils/assets';

type UpdateListingProps = {
    tokenId: number;
    nftContractAddress: string | undefined;
    isFixedSale: boolean;
    price: number | null;
    nftObjectId: string | null;
    shouldRender: boolean;
    setShouldRender: (shouldRender: boolean) => void;
}

const UpdateListing = ({
    tokenId,
    nftContractAddress,
    isFixedSale,
    price,
    nftObjectId,
    shouldRender,
    setShouldRender,
}: UpdateListingProps) => {
    const { chainId } = useParams();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { user } = useChainContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const {
        isOpen: isOpenUpdateListingSuccessModal,
        onOpen: onOpenUpdateListingSuccessModal,
        onClose: onCloseUpdateListingSuccessModal,
    } = useDisclosure();

    const updateFixedSale = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'updateFixedSalePrice',
                args: [
                    nftContractAddress,
                    tokenId,
                    ethers.utils.parseEther(price!.toString()),
                    signature,
                ],
            });

            console.log('tx', tx);

            await axios.post('/fixedSale/updateSalePrice', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
                updatedSalePrice: price,
            });

            await delay(20000);

            onOpenUpdateListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("nft fixed sale update price error: ", e);
                toast.error('Your operation failed: ', e);
            }
        }
    }

    const updateNftAuction = async (signature: string) => {
        try {
            const tx = await writeContract({
                address: nftSellContractAddresses[parseInt(chainId ?? '')],
                abi: nftSellContractAbi,
                functionName: 'updateAuctionSalePrice',
                args: [
                    nftContractAddress,
                    tokenId,
                    ethers.utils.parseEther(price!.toString()),
                    signature,
                ],
            });

            console.log('tx', tx);

            await axios.post('/auction/updateAuctionSalePrice', {
                nftAddress: nftContractAddress,
                tokenId: Number(tokenId),
                chainId: Number(chainId),
                updatedSalePrice: price,
            });

            await delay(20000);

            onOpenUpdateListingSuccessModal();
            setIsProcessing(false);
        } catch (e: any) {
            if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
                toast.error('Please sign in again');
                disconnect();
            } else {
                console.log("nft auction sale update price error: ", e);
                toast.error('Your operation failed: ', e);
            }
        }
    }

    const updateListing = async () => {
        if (!chain || !user) {
            toast.error('Please connect your wallet at first');
            return;
        }
        if (parseInt(chainId ?? '') != chain?.id) {
            await switchNetwork({ chainId: Number(chainId) });
        }
        if (!price || price < 0) {
            toast.error('Please type price at first');
            return;
        }
        setIsProcessing(true);
        const signatureResult = await getSignature(
            user!,
            Number(chainId),
            nftContractAddress!,
            tokenId,
            1, // for compatibility with sign contract
        )
        if (!signatureResult?.isSuccess) {
            toast.error('Signature Error');
            setIsProcessing(false);
            return;
        }

        if (isFixedSale) {
            await updateFixedSale(signatureResult.signature);
        } else {
            await updateNftAuction(signatureResult.signature);
        }
    }

    return (
        <>
            <Button
                border={`1px solid ${secondColor}`}
                borderRadius={'45px'}
                px={'28px'}
                py={'7px'}
                mt={'30px'}
                onClick={() => updateListing()}
                fontFamily={'Poppins'}
                fontSize={'16px'}
                lineHeight={'19px'}
                cursor={'pointer'}
                width={['240px']}
                height={['45px']}
                background={thirdColor}
                color={'white'}
                fontWeight={'900'}
                _hover={{
                    background: secondColor,
                }}
                _selected={{
                    background: secondColor,
                }}
            >
                Update listing
            </Button>
            <AnnounceModal
                isOpenAnnounceModal={isProcessing}
                onCloseAnnounceModal={() => setIsProcessing(false)}
                announceText={'Your transaction is currently processing'}
                announceGif={true}
                announceModalButtonText={'Close'}
            />
            <AnnounceModal
                isOpenAnnounceModal={isOpenUpdateListingSuccessModal}
                onCloseAnnounceModal={() => {
                    setShouldRender(!shouldRender);
                    onCloseUpdateListingSuccessModal();
                }}
                announceText={`${isFixedSale ? 'Fixed Sale Price' : 'Auction'} has been successfully updated`}
                announceLogo={checkIconInGreenBg}
                announceModalButtonText={'Close'}
            />
        </>
    )
}

export default UpdateListing;
