import {
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import axios, { HttpStatusCode } from 'axios';
import { useState, } from 'react';
import { toast } from 'react-toastify';
import { secondColor } from '../../utils/config';
import { useChainContext } from '../../utils/Context';
import { GroupInfo } from '../../utils/types';

export type CreateGroupModalProps = {
  isOpenCreateGroupModal: boolean,
  onCloseCreateGroupModal(): void,
  myGroups: GroupInfo[],
  createGroupHandle: (groupInfo: GroupInfo) => void
}

const CreateGroupModal = ({
  isOpenCreateGroupModal,
  onCloseCreateGroupModal,
  myGroups,
  createGroupHandle
}: CreateGroupModalProps) => {
  const {
    user,
  } = useChainContext();
  const [groupName, setGroupName] = useState<string>('');

  const handleCreateGroup = async () => {
    if (!groupName) {
      toast.error('Please type group name at first');
      return;
    }
    if (myGroups.length >= 5) {
      toast.info('You can\'t create more than 5 groups');
      return;
    }
    if (myGroups.find(item => item.groupName == groupName)) {
      toast.error('That group name is already existed');
      return;
    }

    try {
      const newGroup: GroupInfo = (await axios.post(`/group/createGroup`, {
        groupName,
      })).data;
      console.log("newGroup: ", newGroup);
      toast.success('New group added');
      onCloseCreateGroupModal();
      createGroupHandle(newGroup);
    } catch (e: any) {
      if (e?.response?.status == (HttpStatusCode.Forbidden || HttpStatusCode.Unauthorized)) {
        toast.error('Please sign in again');
      } else {
        console.log("error in handling favorite rate: ", e);
        toast.error('Unfortunately, your operation has failed: ', e);
      }
    }
  }

  return (
    <Modal isOpen={isOpenCreateGroupModal} onClose={onCloseCreateGroupModal}>
      {' '}
      <ModalOverlay
        bg='blackAlpha.10'
        backdropFilter='blur(10px) hue-rotate(10deg)'
      />
      <ModalContent
        border={'1px solid white'}
        borderRadius={'15px'}
      >
        <ModalCloseButton />
        <ModalBody>
          <Flex
            justifyContent={'center'}
            direction={'column'}
            alignItems={'center'}
          >
            <Flex
              width={'100%'}
            >
              <Flex
                fontFamily={'Poppins'}
                fontWeight={'700'}
                fontSize={'25px'}
                lineHeight={'34px'}
                color={'white'}
                textTransform={'capitalize'}
                textAlign={'center'}
              >
                Create Group
              </Flex>
            </Flex>

            <Flex
              gap={['20px']}
              mt={['80px']}
            >
              <Flex
                width={['150px']}
                alignItems={['center']}
              >
                Group Name
              </Flex>

              <Input
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
              />
            </Flex>

            <Button
              onClick={handleCreateGroup}
              mt={'30px'}
              mb={'15px'}
              padding={['5px 40px']}
              height={'46px'}
              minWidth={'155px'}
              width={'fit-content'}
              background={'unset'}
              borderRadius={'34px'}
              border={`1px solid ${secondColor}`}
              fontSize={'18px'}
              fontFamily={'Poppins'}
              color={'white'}
              fontWeight={'900'}
              _hover={{
                background: secondColor,
              }}
              _selected={{
                background: secondColor,
              }}
            >
              Create Group
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CreateGroupModal
